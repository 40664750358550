.gallery {
    &__top {
        margin: 82px 0;
        width: 100%;
    }
    &__slider {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px 36px;
    }
    &__link {
        cursor: pointer;
        height: 100%;
        min-height: 450px;
        img {
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    &__images {
        position: relative;
        display: flex;
        flex-direction: column;
        &:hover {
            .gallery__image-bag {
                background: #000;
                transition: all 0.3s;
                fill: #fff;
            }
            .gallery__image-bag:hover {
                transition: all 0.3s;
                .gal-bag {
                    transition: all 0.3s;
                    fill: #d5ba6c;
                }
            }
            .gal-bag {
                transition: all 0.3s;
                fill: #fff;
            }
            .gallery__image {
                transform: scale(1.1);
                transition: all 0.3s;
            }
            .gallery__size-items {
                display: flex;
                justify-content: space-around;
                z-index: 10;
                transition: all 0.3s;
            }
            .gallery__size {
                bottom: 65px;
                height: 42px;
            }
        }
    }
    &__images-main {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        height: 100%;
        max-height: 448px;
    }
    &__size-items {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: all 0.3s;
        height: 42px;
        background: rgba(255, 255, 255, 0.7);
        color: #a9a9a9;
    }
    &__size-item.have-size {
        color: #000;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            background: #d5ba6c;
            color: #fff;
            transition: all 0.3s;
        }
    }
    &__size-item.active {
        color: #a9a9a9;
        pointer-events: none;
    }
    .active-size {
        background: #d5ba6c;
        color: #fff !important;
        transition: all 0.3s;
    }
    &__size-item {
        color: #000;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            background: #d5ba6c;
            color: #fff;
            transition: all 0.3s;
        }
    }
    &__size-link {
        -webkit-appearance: none;
        -moz-appearance: none;
        color: #000;
    }
    .icon.fav {
        width: 20px;
        height: 20px;
    }
    .icon.gal-bag {
        width: 20px;
        height: 20px;
        transition: all 0.3s;
    }
    &__images-top {
        position: absolute;
        z-index: 4;
    }
    &__discount {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding: 5px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5d402b;
        background: linear-gradient(
            102.82deg,
            #d5ba6c 2.14%,
            #ecdc89 17.68%,
            #faf09f 34.39%,
            #f9eb97 48.97%,
            #bea358 75.14%,
            #d5ba6c 111.36%
        );
    }
    &__fav {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__fav.active-fav {
        background: rgba(255, 255, 255, 1);
        fill: rgb(213, 186, 108);
    }

    &__image-bag {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__image-bag.active-bag > .gal-bag {
        fill: rgb(213, 186, 108);
    }
    &__images > .gallery__image-bag.active-bag > .gal.bag {
        &:hover {
            fill: rgb(213, 186, 108);
        }
    }
    &__fav {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 42px;
        height: 42px;
        background: rgba(255, 255, 255, 0.7);
        z-index: 4;
        transition: all 0.3s;
        .fav {
            transition: all 0.3s;
            fill: #000;
        }

        &:hover {
            transition: all 0.3s;
            background: #fff;
            .fav {
                transition: all 0.3s;
                fill: #d5ba6c;
            }
        }
    }
    &__image {
        transition: all 0.3s;
        height: 100%;
        object-fit: cover;
    }
    &__size {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 3;
        transition: all 0.3s linear;
    }
    &__image-bottom {
        position: absolute;
        bottom: 0;
        z-index: 5;
        display: flex;
        background: #fff;
        z-index: 4;
        width: 100%;
        position: relative;
    }

    &__image-info {
        z-index: 20;
        padding-left: 10px;
    }

    &__image-title {
        padding-top: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #474747;
        padding-bottom: 6px;
    }

    &__image-price {
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 10px;
        &.sale_special {
            color: var(--red);
        }
    }
    &__image-price.sale {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: line-through;
        color: var(--grayNight);
        
    }
    &__image-container {
        display: flex;
        align-items: center;
    }
    &__sale-price {
        margin-right: 10px;
    }
    &__image-bag {
        margin-left: auto;
        margin-right: 10px;
        height: 42px;
        width: 42px;
        margin-top: 10px;
        transition: all 0.3s;
        position: relative;
    }
}

.carouse__item div {
    max-width: 640px;
    max-height: 775px;
}

@media (max-width: 1024px) {
    .gallery__images {
        &:hover {
            .gallery__size {
                bottom: 40px;
            }
        }
    }
    .carouse__item div {
        max-width: 100%;
        max-height: 435px;
        background-size: cover !important;
        background-position: top !important;
    }
}

@media (max-width: 768px) {
    .gallery__link {
        min-height: 205px;
    }
    .gallery__images {
        .gallery__size {
            display: none;
        }
    }
    .gallery__image-price.sale {
        font-size: 10px;
        line-height: 12px;
    }
}
