@media (max-width: 768px) {
    .modal {
        width: 100% !important;
    }
}
@media (max-width: 969px) {
    .closeBtn {
        width: 156px!important;
    }
}
@media (max-width: 568px) {
    .modal {
        width: 96% !important;
        margin: 0 auto!important;
        padding: 16px;
    }
}