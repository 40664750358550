.category {
    &__loader {
        margin: 0 auto;
        display: table;
    }
    &__active-color {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            border: 3px solid #fff;
            z-index: 2;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            transition: all 0.3s;
        }
    }
    &__sub {
        margin-bottom: 48px;
    }
    &__label{
        margin-top: 48px;
    }
    &__content {
        margin-top: 35px;
        margin-bottom: 145px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    &-baner {
        background-image: url(../../../assets/images/pictures/banner.jpg);
        color: #ffffff;
        text-transform: uppercase;
        background-size: cover;
        &__title {
            padding: 20px 0;
            font-weight: 700;
            font-size: 56px;
            line-height: 1.2;
            position: relative;
            z-index: 1;
            flex-flow: column;
            align-items: flex-start;
        }
        &__subtitle {
            padding-bottom: 60px;
            font-weight: 300;
            font-size: 24px;
            line-height: 1.2;
        }
    }
    &__filters {
        margin-right: 36px;
        width: 303px;
        background: #fff;
        color: #474747;
    }

    &__checkbox-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
    }
    &__filter {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__filter:not(:last-child) {
        margin-bottom: 14px;
    }
    &__filtration {
        margin-bottom: 10px;
        display: table;
    }

    &__checkbox {
        appearance: none;
        -webkit-appearance: none;
        background: #f3f3f3;
        width: 24px;
        height: 24px;
        border: 1px solid transparent;
        margin-right: 8px;
        cursor: pointer;
        position: relative;
        transition: all 0.3s;
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/check-default.svg);
            width: 17px;
            height: 13px;
            top: 5px;
            left: 3px;
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
        }
        &::after {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 13px;
            top: 5px;
            left: 3px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
        }
    }
    .category__filter.active {
        font-weight: 700;
        .category__checkbox {
            transition: all 0.3s;
            background: #d5ba6c;
            &::after {
                opacity: 1;
                visibility: visible;
                transition: all 0.3s;
            }
            &::before {
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;
            }
        }
        .category__check {
            font-weight: 700;
        }
    }
    &__check {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #474747;
        cursor: pointer;
    }

    &__size {
        padding-top: 20px;
    }
    &__color-top,
    &__price-top,
    &__size-top,
    &__cheboxes-top {
        justify-content: space-between;
    }
    &__cheboxes-top,
    &__color-top,
    &__price-top,
    &__size-items,
    &__size-top {
        display: flex;
        flex-wrap: wrap;
        .category__size-product {
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
        padding-bottom: 20px;
        color: #000;
    }
    &__price-title,
    &__size-title,
    &__color-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
    }

    &__reset {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #d5ba6c;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            color: #000000;
        }
    }

    &__price {
        padding-top: 48px;
    }
    .rc-slider-handle:active {
        box-shadow: none;
    }
    .rc-slider {
        top: -5px;
        margin: 0 auto;
        width: 284px !important;
    }
    .rc-slider-handle {
        width: 24px;
        height: 24px;
        background: #d5ba6c;
        border-radius: 0;
        border: none;
        opacity: 1;

        margin-top: -10px;
    }
    .rc-slider-track {
        background: #d5ba6c;
        height: 2px;
        position: relative;
        top: -2px;
    }
    .rc-slider-rail {
        height: 2px;
        width: 303px;
        position: relative;
        left: -10px;
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        background: #d5ba6c;
        box-shadow: none;
    }
    .rc-slider-handle.rc-slider-handle-1,
    .rc-slider-handle.rc-slider-handle-2 {
        margin-top: -14px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    &__price-content {
        display: flex;
        padding: 17px 0;
        border: 1px solid #f3f3f3;
        position: relative;
    }
    &__field {
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    &__min,
    &__max {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #a9a9a9;
        position: relative;
        top: 2px;
        text-align: left;
        padding-left: 20px;
        white-space: nowrap;
        padding-right: 5px;
    }

    &__min-price,
    &__max-price {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #474747;
        padding-right: 5px;
        text-align: left;
        width: 100%;
        outline: none;
        border-color: transparent;
    }

    &__slider {
        height: 2px;
        background: #e1ded5;
        border-radius: 20px;
        position: relative;
    }

    &__bar {
        height: 2px;
        background: #d5ba6c;
        border-radius: 20px;
        position: absolute;
    }

    &__range-input {
        position: relative;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    &__range-input input {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        background: none;
        pointer-events: none;
        -moz-appearance: textfield;
    }
    &__range-max,
    &__range-min {
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 24px;
            height: 24px;
            background: #d5ba6c;
            pointer-events: auto;
            border: none;
            border-radius: 0;
        }
    }
    &__range-max,
    &__range-min {
        &::-moz-range-thumb {
            -moz-appearance: none;
            width: 24px;
            height: 24px;
            background: #d5ba6c;
            pointer-events: auto;
            border: none;
            border-radius: 0;
        }
    }
    &__color {
        padding-top: 20px;
    }

    &__color-items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 23px;
        grid-row-gap: 20px;
        margin-bottom: 48px;
    }

    &__clear {
        cursor: pointer;
        margin-top: 48px;
        display: inline-block;
        transition: all 0.3s;
        background: #d5ba6c;
        color: #ffffff;
        &:hover {
            transition: all 0.3s;
            color: #000000;
            background: #f3f3f3;
        }
    }

    &__clear-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 16.5px 24px;
        text-align: center;
    }

    &__size-item {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        background: #f3f3f3;
        transition: all 0.3s;
        &:hover {
            color: #ffffff;
            background: #d5ba6c;
        }
    }

    &__size-product {
        min-width: 42px;
        padding: 0 10px;
        height: 42px;
        display: flex;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        align-items: center;
        font-weight: 500;
        font-size: 17px;
        line-height: 1.2;
        text-align: center;
        background: #f3f3f3;
        transition: all 0.3s;
        input {
            display: none;
        }
        &:hover {
            color: #ffffff;
            background: #d5ba6c;
        }
    }
    &__color-item {
        width: 42px;
        height: 42px;
        background: #d5ba6c;
        cursor: pointer;
        border: 1px solid transparent;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            border: 3px solid transparent;
            z-index: 2;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            transition: all 0.3s;
        }
    }
    &__active-color {
        position: relative;
        border: 1px solid #474747;
        &::after {
            content: "";
            position: absolute;
            border: 3px solid #fff;
            z-index: 2;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            transition: all 0.3s;
        }
    }
}

.terms_agree {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 30px;
    label {
        padding-right: 20px;
    }
    .hidden_checkbox {
        display: none;
    }
    &_link {
        &:hover {
            transition: all 0.3s;
            color: #d5ba6c;
        }
    }
}
.is-sticky {
    position: fixed;
    top: 10px;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.active-category {
    transition: all 0.3s;
    color: #ffffff;
    background: #d5ba6c;
}
.filter {
    display: none;
}
.filter__icon,
.mobile__menu-arrow {
    cursor: pointer;
}
.mobile__header-filter {
    display: none;
}

.category-baner {
    min-height: 230px;
    .category-baner__title {
        min-height: 230px;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 1200px) {
    .mobile__header-filter {
        display: block;
    }

    .category__filters.active {
        left: 0;
        transition: all 0.3s;
    }
    .category {
        &__cheboxes {
            margin-top: 20px;
        }
        &__filters {
            margin-right: 0px;
            width: 330px;
            position: fixed;
            overflow-x: hidden;
            background: #ccc;
            top: 0;
            padding: 0 10px;
            z-index: 99999;
            height: 100vh;
            background: #fff;
            left: -1000px;
            transition: all 0.3s;
            overflow-y: scroll;
        }

    }

    .baner__container {
        position: relative;
    }

    .filter {
        display: block;
        position: relative;
        background: #fff;
    }

    .icon.filter-icon {
        fill: #474747;
        width: 16px;
        height: 16px;
    }
    .filter__icon {
        position: absolute;
        right: 20px;
        top: 15px;
    }
    .filter__input {
        height: 44px !important;
        margin-bottom: 0 !important;
        border: 1px solid #474747;
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: #474747;
    }
    .filter {
        cursor: pointer;
    }

    .category__clear {
        margin-bottom: 30px;
    }
    .category-pagination {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1200px) {
    .category {
        &__content {
            display: block;
            margin-top: 30px;
        }
    }
}
@media (min-width: 1024px) {
    // .category-baner__title {
    //     padding-top: 60px;
    // }
}
@media (max-width: 1024px) {
    .category-baner {
        // background: none;
        text-align: center;
    }
    .category-baner__title {
        font-size: 30px;
        line-height: 1.2;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }
    .category-baner__subtitle {
        padding-bottom: 10px;
        font-size: 18px;
        line-height: 25px;
    }
    .product,
    .category.wish,
    .cart,
    .registration,
    .category,
    .blog-item,
    .about,
    .profile,
    .privacy-page,
    .contact,
    .search-page {
        .breadcrumbs {
            position: absolute;
            top: 70px;
            padding-top: 0;
        }
    }
}

@media (max-width: 768px) {
    .category {
        .gallery__slider {
            grid-template-columns: repeat(2, 1fr);
        }
        &__content {
            margin-bottom: 40px;
        }
        &-baner {
            .category-baner__title {
                min-height: 260px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .breadcrumbs {
        display: none;
    }

    .baner__container {
        margin-bottom: 30px;
    }
}
