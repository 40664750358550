.cart {
    &__phone-input--container {
        position: relative;
    }

    .bag-popup__details {
        align-items: flex-start;
    }

    &__phone-initial {
        position: absolute;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #474747;
        left: 20px;
        top: 17px;
    }

    &__image-link {
        width: 188px;
        height: 228px;
        cursor: pointer;
        position: relative;
    }

    &__product-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        
    }

    &__contact-phone {
        padding-left: 70px !important;
    }

    &__product-main {
        display: flex;
        margin-top: 34px;
        justify-content: space-between;
        margin-bottom: 90px;
        color: #000000;
    }

    &__registration {
        width: 100%;

        input[type="number"] {
            margin-bottom: 25px;
            padding: 0px 20px 0px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 52px;
            border: 1px solid #a9a9a9;
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #474747;
            transition: all 0.3s;
        }

        input[type="number"]:focus {
            border: 1px solid #474747;
            border-radius: 0;
            outline: none;
            transition: all 0.3s;
        }

        input[type="number"]::placeholder {
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            color: #a9a9a9;
        }
    }

    &__registration {

        input[type="text"],
        input[type="number"],
        input[type="password"] {
            margin-bottom: 16px;
        }

        .cart__payment-comment--input input {
            margin-bottom: 30px;
        }
    }

    &__content {
        margin-right: 38px;
        width: 100%;

        .pagination {
            margin-bottom: 36px;
            margin-top: 48px;
        }
    }

    .scroll-address {
        max-height: 160px;
        overflow-y: scroll;
        scrollbar-color:#d5ba6c #f2f1ec ;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #ccc;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #888;
        }
    }

    &__product {
        display: flex;
        &:not(:last-of-type)  {
            margin-bottom: 36px;
        }
    }


    &__product-about {
        padding-left: 36px;
    }

    &__about-title {
        padding-top: 25px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
    }

    .minus-btn,
    .plus-btn {
        width: 48px;
        height: 52px;
        background: #f3f3f3;
        border: 1px solid transparent;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.05em;
        font-size: 22px;
    }

    .bag-popup__counter {
        transition: all 0.3s;
        height: 52px;
        width: 50px;
        text-align: center;
        -webkit-appearance: none;
        font-size: 22px;
    }

    &__about-size {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #474747;

        span {
            margin-left: 10px;
            color: #000000;
        }
    }

    &__about-description {
        margin-top: 8px;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .plus-btn.product__plus-btn,
    .minus-btn.product__minus-btn {
        width: 47px;
        height: 52px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #a9a9a9;
    }

    .bag-popup__counter.product__counter {
        width: 49px;
        height: 52px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #474747;
    }

    &__product-price {
        padding-top: 24px;
        text-align: right;
        margin-left: auto;
    }

    &__price-sum {
        font-weight: 800;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
        padding-bottom: 63px;
        text-transform: uppercase;
        &.sale {
            color: var(--red);
        }
    }

    &__remove {
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 13px;
            background-image: url(../../../assets/images/icons/cart-remove.svg);
            right: 80px;
            top: 4px;
            cursor: pointer;
        }
    }

    &__remove-text {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        position: relative;
        padding-left: 20px;
    }

    &__inputs {
        background: #f3f3f3;
        display: flex;
        justify-content: space-between;

        .error-mesage {
            margin-top: -100px;
        }
    }

    &__coupon {
        margin-top: 10px;
        margin-left: 20px;
        width: 100%;
    }

    &__coupon-title {
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        padding-top: 10px;
    }

    &__coupon-enter {
        display: flex;
    }

    &__coupon-input {
        max-height: 52px;
        width: 100%;
        padding: 14px 0px 18px 21px;
        max-width: 280px;
    }

    &__coupon-update {
        cursor: pointer;
        padding: 16.5px 24.5px;
        height: 52px;
        max-width: 116px;
        text-align: center;
        border: 1px solid #a9a9a9;
        border-left: 1px solid transparent;
        background: transparent;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;

        &:hover {
            transition: all 0.3s;
            background: #000000;
            color: #fff;
            border: 1px solid #000;
        }
    }

    .error-message {
        color: #e80000;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    &__discount {
        padding-top: 10px;
        padding-right: 20px;
        width: 100%;
        margin-left: 34px;
        position: relative;
    }

    &__discount-title {
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        padding-top: 10px;
    }

    &__discount-enter {
        display: flex;
    }

    &__discount-input {
        width: 100%;
        padding: 14px 0px 18px 21px;
        max-width: 280px;
    }

    &__total {
        padding-top: 36px;
    }

    &__total-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 20px;
        align-items: center;
        letter-spacing: 0.05em;
        color: #131313;
    }

    &__back {
        margin-top: 50px;
        margin-bottom: 137px;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
    }

    &__back-link {
        padding-left: 37px;
        position: relative;
        transition: all 0.3s;

        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/continue-shop.svg);
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            top: 0px;
            left: 0;
            transition: all 0.3s;
        }

        &:hover {
            color: #d5ba6c;
        }

        &:hover::before {
            background-image: url(../../../assets/images/icons/continue-shop-hov.svg);
            top: 0px;
            left: 0;
            width: 24px;
            height: 24px;
        }
    }

    &__registration {
        max-width: 416px;
        position: relative;
    }

    .cart-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    &__registration-log {
        border: 1px solid #a9a9a9;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
    }

    &__contacts {
        border-bottom: 1px solid #a9a9a9;
        transition: all 0.3s;
        top: 50px;
        display: none;
    }

    .active-contact {
        display: block;
    }

    &__contact-top {
        display: flex;
        padding-bottom: 16px;
        justify-content: space-between;
    }

    &__contact-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #131313;
    }

    &__contact-log {
        margin-left: auto;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.1s;
        cursor: pointer;

        &:hover {
            color: #131313;
        }
        &.signup {
            color: #131313;
            &:hover {
                color: #d5ba6c;
            }
        }
    }

    &__contact-inputs {
        display: block;
        margin-bottom: 30px;
    }

    &__login {
        border-bottom: 1px solid #a9a9a9;
        display: none;
    }

    .active-login {
        display: block;
    }

    &__login-title {
        padding-bottom: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #131313;
    }

    &__login-btns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &__login-back {
        cursor: pointer;
        transition: all 0.3s;
        background: #fff;
        color: #000;
        border: 1px solid #a9a9a9;
        padding: 16.5px 35.5px;
        transition: all 0.3s;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        &:hover {
            background: #000000;
            border: 1px solid #000;
            color: #fff;
            border: 1px solid #a9a9a9;
        }
    }

    &__login-log {
        background: #000000;
        border: 1px solid #000;
        color: #fff;
        padding: 16.5px 31.5px;
        transition: all 0.3s;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        &:hover {
            transition: all 0.3s;
            background: #fff;
            color: #000;
            border: 1px solid #a9a9a9;
        }
    }

    &__delivery {
        padding-bottom: 30px;
        border-bottom: 1px solid #a9a9a9;
        scrollbar-color:#d5ba6c #f2f1ec ;
        scrollbar-width: thin;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    &__delivery-title {
        padding-top: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #131313;
    }

    &__delivery-items {
        padding: 17px 0 17px 20px;
        background: #fff;
        transition: all 0.3s;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        cursor: pointer;
        margin: 0 30px;
    }

    &__delivery.active {
        .cart__delivery-item.active-delivery {
            display: block;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 18px;
                right: 20px;
                background-image: url(../../../assets/images/icons/drop-white.svg);
                width: 17px;
                height: 17px;
                background-repeat: no-repeat;
            }
        }
    }

    .cart__delivery-item.active-delivery.open {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 15px;
            right: 20px;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 17px;
            background-repeat: no-repeat;
        }
    }

    &__delivery-items.active {
        background: #000;
        color: #fff;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 15px;
            right: 20px;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 17px;
            background-repeat: no-repeat;
        }
    }

    &__delivery-list {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 30px;
        color: #fff;
        max-height: 200px;
        overflow: auto;
    }

    .new-active-delivery {
        display: block;
        grid-row: 1;
    }

    &__delivery-item {
        padding: 17px 0 17px 20px;
        background: #474747;
        color: rgb(194, 194, 194);
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        transition: all 0.3s;
        cursor: pointer;
        display: none;

        &:hover {
            transition: all 0.3s;
            color: #fff;
        }
    }

    &__delivery-item.active-delivery {
        color: #fff;
        cursor: default;
        grid-row: 1;
        display: none;
    }

    &__delivery-item.open {
        display: block;
    }

    &__item-input {
        cursor: pointer;
    }

    &__item-text {
        cursor: pointer;
    }

    &__payment {
        border-bottom: 1px solid #a9a9a9;
        font-weight: 400;
    }

    &__payment-title {
        padding-bottom: 16px;
        padding-top: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #131313;
    }

    &__payment-online.active {
        color: #fff;
        background: #000;
        transition: all 0.3s;
    }

    &__payment-online {
        padding: 17px 0 17px 20px;
        background: transparent;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        position: relative;
        transition: all 0.3s;
        color: #474747;
        margin-bottom: 15px;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/cart-check-grey.svg);
            width: 17px;
            height: 13px;
            transition: all 0.3s;
        }
    }

    .active-payment-online {
        background: #000;
        color: #fff;
        transition: all 0.3s;
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/cart-check-black.svg);
            width: 17px;
            height: 12px;
            transition: all 0.3s;
            cursor: pointer;
        }
    }

    // &__total {
    //     // padding-left: 20px;
    //     // padding-right: 20px;
    // }

    &__online-text {
        cursor: pointer;
        transition: all 0.3s;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    .delivery-choose {
        margin-bottom: 30px;
    }

    &__payment-cash.payment {
        margin-bottom: 16px;
    }

    &__payment-cash {
        padding: 17px 0 17px 20px;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        font-weight: 400;
        cursor: pointer;
        transition: all 0.3s;
        color: #474747;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/cart-check-grey.svg);
            width: 17px;
            height: 13px;
            transition: all 0.3s;
        }
    }

    &__payment-cash.active {
        font-weight: 400;
        background: #000;
        color: #fff;
        transition: all 0.3s;
        position: relative;
        cursor: default;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/cart-check-black.svg);
            width: 17px;
            height: 12px;
            transition: all 0.3s;
            cursor: pointer;
        }
    }

    &__add-btn--container {
        background: #474747;
    }

    &__add-btn {
        cursor: pointer;
        margin-left: 20px;
        margin-right: 20px;
    }

    &__add-addres.active {
        display: block;
    }

    &__add-addres {
        display: none;
        cursor: pointer;
        width: 100%;
        background: #000;
        border: 1px solid #000;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 16.5px 0;
        transition: all 0.3s;

        &:hover {
            transition: all 0.3s;
            color: #000;
            background: #fff;
        }
    }

    &__city-container,
    &__location-container {
        background: #474747;
    }

    &__delivery-input--add.active {
        height: auto;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s;
    }

    &__delivery-input--add {
        opacity: 0;
        visibility: hidden;
        height: 0;
    }

    &__delivery-add.active {
        transition: all 0.3s;
        transform: translateY(-50px);
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__delivery-add {
        background: #474747;
        transition: all 0.3s;
        margin-left: 30px;
        margin-right: 30px;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__city {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        display: none;
    }

    &__city-input {
        margin-top: 0px;
        margin-bottom: 0px !important;
    }

    &__location {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background: #474747;
        display: none;
    }

    &__payment-take {
        margin-top: 15px;
    }

    &__location-input {
        margin-bottom: 0px !important;
    }

    .active-place {
        display: block;
    }

    .active-courier-btn {
        transition: all 0.3s;
        display: inline-block;
    }

    &__cash-text {
        cursor: pointer;
        transition: all 0.3s;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    &__payment-comment--input {
        margin-top: 16px;
    }

    &__payment-comment {
        padding: 24px 21px 80px 21px !important;
    }

    &__checkout {
        margin-top: 30px;
        cursor: pointer;
    }

    .test {
        background: red;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__checkout-btn {
        width: 100%;
        padding: 16.5px 75.5px;
        background: #d5ba6c;
        color: #fff;
        border: 1px solid #d5ba6c;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        max-height: 52px;
        white-space: nowrap;
        transition: all 0.3s;
        display: flex;
        justify-content: center;

        &:hover {
            transition: all 0.3s;
            color: #000;
            border: 1px solid #000000;
            background: transparent;
        }
    }

    &__add-city input {
        width: 100%;
    }

    &__delivery-item-info {
        margin-bottom: 16px;

        .address {
            display: none;
        }

        .cart__add-address {
            background: #474747;
            padding: 20px;
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .cart__add-address--text {
            height: 52px;
            display: none;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #000;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__add-location {
        display: none;
    }

    &__add-location.active {
        display: block;
    }

    &__delivery-item-info.active {
        .address {
            display: block;
        }

        .cart__add-address {
            display: block;
        }

        .cart__add-address--text {
            &:hover {
                color: #000;
                background: #fff;
                transition: all 0.3s;
            }
        }

        .cart__add-address--text {
            display: flex;
            cursor: pointer;
            transition: all 0.3s;
        }
    }

}

body .cart .cart__city-drop {
    margin-bottom: 20px;
    width: 100%;

    [class^=' css'][class$='menu'] {
        width: 100%;
    }

    [class^=' css'][class$='Input'] {
        height: 52px;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #474747;
        transition: all 0.3s;
        border: 1px solid #a9a9a9;
        padding: 0px 20px 0px 20px;
        background-color: #fff !important;
    }

    [class^=' css'][class$='control']::after{
        background-image: url(../../../assets/images/icons/down-arr.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: none;
    }

    [class^=' css'][class$='singleValue'],
    [class^=' css'][class$='placeholder'] {
        color: #474747;
        z-index: 1;
    }
}

html body .cart {
    &__inputs {
        .error-mesage {
            margin-top: 55px;
        }
    }
}

body .cart .cart__registration {
    [class^=' css'][class$='ValueContainer'] {
        padding: 0;
    }

    [class^=' css'][class$='control'] {
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: all 0.3s;
        background: none;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 13px;
            transition: all 0.3s;
        }
    }

    [class^=' css'][class$='placeholder'] {
        margin: 0;
    }

    [class^=' css'][class$='control'] {
        border: none;
        box-shadow: none;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 13px;
            transition: all 0.3s;
        }
    }

    [class^=' css'][class$='control']:hover {
        border: none;
    }

    [class^=' css'][class$='placeholder'] {
        color: #000;
        transition: all 0.3s;
    }

    [class^=' css'][class$='control']:hover {
        border: none;
        box-shadow: none;
    }

    [class^=' css'][class$='singleValue'] {
        color: #fff;
    }

    [class^=' css'][class$='control'],
    [class^=' css'][class$='control'] {
        background: #474747;
        color: #fff;
        height: 52px;
        display: flex;
        align-items: center;
        border-radius: 0;
    }

    [class^=' css'][class$='option'] {
        background: #474747;
        color: #fff;
        height: 52px;
        display: flex;
        align-items: center;
        border-radius: 0;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: 20px;
            background-image: url(../../../assets/images/icons/check.svg);
            width: 17px;
            height: 13px;
            transition: all 0.3s;
        }
    }

    [class^=' css'][class$='menu'] {
        box-shadow: none;
        margin: 0;
    }

    [class^=' css'][class$='placeholder'] {
        color: #fff;
    }

    [class^=' css'][class$='option'] {
        background: #474747;
        color: #ccc;
        height: 52px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    //hover
    [class^=' css'][class$='option'] {
        background: #474747;
        color: #fff;
        height: 52px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    [class^=' css'][class$='option'],
    [class^=' css'][class$='option'],
    [class^=' css'][class$='option'] {
        padding-left: 20px;
    }

    [class^=' css'][class$='singleValue'] {
        margin-left: 0;
        padding-left: 20px;
        margin-right: 40px;
    }

    [class^=' css'][class$='placeholder'] {
        padding-left: 20px;
        padding-right: 20px;
    }

    // [class^=' css'][class$='menu'] {
    //     width: 100%;
    // }

    [class^=' css'][class$='MenuList'] {
        padding: 0;
    }


    [class^=' css'][class$='option'] {
        padding-right: 60px;
        overflow: hidden;
    }

    [class^=' css'][class$='control'] {
        border-radius: 0;
    }

    [class^=' css'][class$='menu'] {
        position: relative;
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        
    }

    [class^=' css'][class$='MenuList'] {
        padding-top: 0;
        padding-bottom: 0;
        
    }

    .error-mesage {
        margin-top: -18px;
    }
}

.order {
    .cart__total-list:last-child {
        border-bottom: none;
    }

    background: #fff;
    width: 800px;
    padding: 40px 50px 20px 50px;
    position: relative;
    margin: 0 10px;

    &__title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        margin-bottom: 25px;
    }

    &__id {
        text-align: center;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    &__info {
        border: 1px solid #a9a9a9;
        padding: 30px;
        padding-bottom: 0;
    }

    &__date,
    &__payment,
    &__delivery {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
    }

    &__items {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }

    &__discount {
        background: #f3f3f3;
        padding: 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        width: 100%;
        height: 60px;
        margin-bottom: 8px;
    }

    .cart__total {
        padding-top: 0;
        padding: 0 20px;
    }

    .cart__total-list {
        font-weight: 600;
        font-size: 20px;
        line-height: 52px;
        letter-spacing: 0.05em;
        color: #131313;
        margin-bottom: 10px;
    }

    .all-price {
        font-weight: 700;
    }

    &__home {
        // width: 204px;
        width: 50%;
        height: 52px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
        background: #000000;
        margin: 0 auto;
        margin-top: 40px;
        border: 1px solid transparent;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            border: 1px solid #000;
            transition: all 0.3s;
            color: #000;
            background: #fff;
        }
        &.full-width {
            width: 100%;
        }
    }
    .icon.pay-icon {
        fill: white;
        stroke: white;
    }

}

.paynet {
    .svgicon {
        width: 24px;
        height: 100%;
        margin-right: 10px;
        &::before {
            background-image: url(../../../assets/images/icons/paynet.svg);
            max-width: 24px;
            height: 100%;
            content: "";
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            background-position: center;
        }

    }
    &:hover {
        .svgicon::before {
            background-image: url(../../../assets/images/icons/paynet-bk.svg);
        }
    }
}


.cart__total-list.all-price {
    font-weight: 700;
}

.empty {
    &__cart {
        margin-top: 34px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__cart-img {
        width: 200px;
        height: 200px;
    }

    &__cart-title {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #131313;
        margin: 40px 0 25px 0;
    }

    &__cart-text {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 40px;
    }

    &__cart-btn {
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 240px;
        height: 52px;
        color: #ffffff;
        background: #000000;
        transition: all 0.3s;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid #000000;
            color: #000000;
            background: #fff;
            transition: all 0.3s;
        }
    }
}

@media (max-width: 1200px) {
    .cart {
        &__checkout-btn {
            font-size: 13px;
            line-height: 16px;
        }

        .bag-popup__counter {
            width: 48px;
            height: 52px;
            max-height: 52px !important;
        }

        &__total {
            padding-left: 0px;
            padding-right: 0px;
        }

        &__product-main {
            margin-top: 20px;
        }

        &__product-main {
            display: block;
        }

        &__registration-log {
            padding-top: 0;
        }

        &__total {
            padding-top: 10px;
        }

        &__product:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &__remove-text {
            font-size: 0;
        }

        &__remove {
            color: transparent;

            &::before {
                left: auto;
                right: 0px;
                top: -20px;
            }
        }

        &__delivery {
            padding-bottom: 0;
        }

        &__payment-title {
            padding-top: 40px;
        }

        &__price-sum {
            position: absolute;
            white-space: nowrap;
            top: 0px;
            right: 20px;
            right: 0;
        }

        &__product-price {
            position: relative;
        }

        &__product-about {
            padding-left: 20px;
        }

        &__inputs {
            display: block;
        }

        &__discount {
            padding-top: 10px;
            margin-right: 10px;
            margin-left: 10px;
        }

        &__coupon {
            padding-top: 10px;
            margin-right: 10px;
            margin-left: 10px;
        }

        &__coupon-input,
        &__discount-input {
            max-width: 100%;
        }

        &__coupon {
            padding-right: 20px;
        }

        &__back {
            margin-bottom: 50px;
            margin-top: 30px;
        }

        &__registration-log {
            border: none;
        }

        .cart-container {
            padding-left: 0px;
            padding-right: 0px;
        }

        &__payment-cash,
        &__payment-online,
        &__delivery-shop,
        &__delivery-courier {
            padding-left: 10px;
        }

        input[type="text"],
        input[type="password"],
        input[type="number"] {
            padding: 22px 20px 18px 10px;
            max-height: 44px;
        }

        &__registration {
            .cart__payment-comment--input input {
                margin-bottom: 64px;
            }
        }

        .cart__payment-comment {
            padding-left: 10px !important;
        }

        &__registration-log {
            padding-bottom: 0px;
            margin-bottom: -40px;
        }

        &__coupon-update {
            height: 44px;
            padding: 10.5px 24.5px;
        }

        &__total-list {
            font-size: 14px;
        }

        &__total-list:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.18);
            margin-bottom: 0;
            padding-bottom: 20px;
        }

        &__back {
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
        }

        &__payment,
        &__delivery,
        &__contacts {
            border: none;
        }

        &__checkout-btn {
            max-height: 44px;
        }

        &__price-sum {
            font-size: 16px;
            line-height: 19px;
            position: absolute;
            bottom: -35px;
            top: auto;
        }

        &__about-title {
            padding-top: 0px;
        }

        &__product-image {
            max-width: 86px;
            max-height: 86px;
        }

        &__registration {
            max-width: 100%;
        }

        &__about-title {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .cart .bag-popup__counter.product__counter {
        width: 41px;
        height: 35px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #474747;
    }

    .cart .plus-btn.product__plus-btn,
    .cart .minus-btn.product__minus-btn {
        width: 35px;
        height: 35px;
    }

    .cart__product {
        input[type="number"] {
            padding: 0;
        }
    }
}

body .cart [class^=' css'][class$='singleValue'] {
    max-width: 310px;
}

@media (max-width: 1024px) {

    body .cart [class^=' css'][class$='option'],
    body .cart [class^=' css'][class$='placeholder'],
    body .cart [class^=' css'][class$='option'],
    body .cart [class^=' css'][class$='option'],
    body .cart [class^=' css'][class$='singleValue'] {
        padding-left: 10px;
    }

    .cart__delivery-item-info .cart__add-address {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 968px) {
    .empty__cart-title {
        font-size: 24px;
        line-height: 36px;
        max-width: 240px;
    }

    .empty__cart-btn {
        width: 100%;
    }

    .order {
        padding: 40px 10px 20px 10px;

        &__title {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 15px;
            max-width: 160px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        &__info {
            padding: 10px;
        }

        &__id {
            margin-bottom: 25px;
        }

        &__items {
            font-size: 16px;
            line-height: 19px;
        }

        &__discount {
            width: 100%;
            height: 40px;
            margin-bottom: 0px;
            padding: 10px;
        }

        .cart__total {
            padding: 0 10px;
        }

        .cart__total-list {
            font-size: 14px;
            line-height: 52px;
            margin-bottom: 0;
        }

        &__home {
            width: 100%;
            margin-top: 25px;
        }
    }

    .cart {

        .minus-btn,
        .plus-btn {
            width: 35px;
            height: 35px;
        }

        .bag-popup__counter {
            width: 40px;
            height: 35px;
        }
    }
}
.cart_address {
    h3 {
        text-transform: uppercase;
        font-size: 30px;
        margin-bottom: 40px;
    }
    .popup__default-title {
        font-size: 17px;
    }
    .popup__default-input {
        margin-bottom: 23px!important;
    }
    .popup__default-input_bottom {
        margin-bottom: 0!important;
    }
    .cart-popup__default-input_border-top {
        border-top: 1px solid #a9a9a9;
        margin: 20px 0;
    }
    .str {
        display: flex;
    }
}
.cart_modals {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        color: var(--blackTwo);
        text-transform: uppercase;
        text-align: center;
    }
    .content p:first-child {
        color: var(--grayNight);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin-top: 20px;
    }
    .content p:last-child {
        color: var(--black);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        margin-top: 36px;
        margin-bottom: 20px;
    }
    .contact_social {
        margin-top: 0;
        display: flex;
        justify-content: center;
        .footer__social-link {
            height: 24px;
            width: 24px;
        }
    }
    .footer__social-link {
        display: flex;
        .icon {
            fill: #000;
        }
    }

}
@media (max-width: 578px) {
    .cart {
        &__image-link {
            width: 70px;
            height: 86px;
        }
    }
    .cart_address {
        .str {
            flex-wrap: wrap;
            justify-content: space-between;
            &_item {
                width: 47%;
            }
            &_item {
                &:nth-child(1),
                &:nth-child(3) {
                    margin-right: 10px!important;
                }
                &:nth-child(2),
                &:nth-child(4) {
                    margin-right: 0!important;
                }
            }
        }
    }
}
@media (max-width: 380px) {
    .cart_address {
        .popup__address {
            width: 320px;
        }
    }
}