.mobile {
    &__menu {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        transform: translateX(-100%);
        transition: transform 0.2s;
        z-index: 100;
        max-width: 380px;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        &-arrow {
            display: flex;
            align-items: center;
        }
        &.lg {
            max-width: 690px;
        }
        &-currency {
            display: none;
            justify-content: center;
            align-items: center;
            gap: 30px;
            align-self: stretch;
           
            label {
                position: relative;
                display: flex;
                padding-left: 28px;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
                color: var(--text-dark-primary, #FFF);
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
            }
            .checkmark {
                position: absolute;
                top:auto;
                left: 0;
                height: 16px;
                width: 16px;
                border: 2px solid #A9A9A9;
                border-radius: 50%;
                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 2px;
                    left: 2px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #D5BA6C;
                }
            }
            input:checked ~ .checkmark {
               border-color: #D5BA6C;
            }
            input:checked ~ .checkmark:after {
                display: block;
              }
              
        }
    }

    &__menu.active {
        transform: translateX(0);
        .mobile__content {
            transform: translateX(0%);
        }
    }

    &__search {
        border: 1px solid transparent !important;
        border-bottom: 1px solid #a9a9a9c3 !important;
        padding-left: 0 !important;
        padding-bottom: 16px !important;
        background-color: #000 !important;
        margin-bottom: 0 !important;
    }
    &__search-icon {
       
    }

    &__right-user {
        fill: #F6F4EA;
    }

    &__menu-search {
        position: relative;
        display: none;
    }


    &__menu-btns {
        text-transform: uppercase;
        display: flex;
        letter-spacing: 0.05em;
        color: #474747;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        gap: 10px;
        padding: 10px 20px 10px 0;
        box-sizing: border-box;
    }

    &__menu-new {
        border: 1px solid var(--red);
        padding: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        transition: all .2s;
        color: var(--red);

        &:hover {
            border: 1px solid var(--red);
            background: var(--red);
            color: #fff;
        }
    }

    &__menu-sale {
        border: 1px solid #474747;
        padding: 10px;
        max-width: 160px;
        height: 44px;
        &:hover {
            border: 1px solid transparent;
            background: #000;
            color: #fff;
        }
    }

    &__menu-content {
        display: flex;
    }

    &__menu-items {
        scrollbar-width: thin;
        font-weight: 500;
        font-size: 15px;
        line-height: 16.8px;
        color: #fff;
        text-transform: uppercase;

    //~ Firefox add extra space to flex-direction: column

        // display: flex;
        // flex-wrap: nowrap;
        // flex-direction: column;
        min-height: 0;

        width: 100%;
        -webkit-overflow-y:overlay;
        -moz-overflow-y:overlay;
        -ms-overflow-y: overlay;
        -o-overflow-y: overlay;
        overflow-y: overlay;
        scrollbar-gutter: stable;
        -webkit-scrollbar-gutter:stable;
        -moz-scrollbar-gutter:stable;
        -ms-scrollbar-gutter: stable;
        -o-scrollbar-gutter: stable;
        padding-right: 20px !important;
        
        .menu__item-link {
            position: relative;    
                .icon-container {
                    padding: 16px 0;
                   // padding-right: 5px;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    svg {
                        stroke: #A9A9A9;
                        fill: none;
                        transition: all .2s;
                       
                    }
                    &:hover {
                        svg {
                            stroke: #D5BA6C !important;
                            fill: none;
                        }
                    }
            }
        }
        .menu__item-children {
            box-shadow: none;
        }
    }
    &__menu-item {
        .menu__item-link {
            justify-content: space-between;
            cursor: pointer;
            &.active {
                color: #d5ba6c;
                background: rgba(255, 255, 255, 0.10);
                .icon-container svg {
                    stroke: #d5ba6c;
                }
            }
            span {
                width: 100%;
                padding: 16px 0;
            }
        }
    }

    &__items {
        display: flex;
    }
    &__menu-footer {
        margin-top: auto;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-top: 20px;
    }
}

.search-mobile {
    width: 17px;
    height: 19px;
    fill: #474747;
    position: absolute;
    right: 0;
    top: 10px;
}

.mobile-menu {
    height: 22px;
    width: 24px;
    stroke: #fff;
    &:hover {
        stroke: #D5BA6C;
    }
}
.mobile__menu-close {
    width: 26px;
    height: 26px;
    //padding: 4px;
    cursor: pointer;
    transition: all .2s;
    svg {
        width: 26px;
        height: 26px;
        fill: #a9A9A9;
    }
    &:hover {
        svg {
            fill:  #D5BA6C;
        }
        
    }
}
.close-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    
}
.mobile__header-link {
    display: flex;
    align-items: center;
    justify-content: center;
    svg stop { 
        transition: all .2s;
    }
    svg:hover stop {
        stop-color: #fff;
    }
}
.facebook-mobile {
    width: 13px;
    height: 23px;
}

.twitter-mobile {
    width: 17px;
    height: 19px;
}

.instagram-mobile {
    width: 18px;
    height: 23px;
}
.telegram-mobile {
    width: 20px;
    height: 23px;
}

.user-mobile {
    width: 16px;
    height: 16px;
    transition: all 0.2s;
   
}

.menu-title {
    color: var(--lea-white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
        color: #D5BA6C;
    }
}
.mobile__search-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 300px;
}

@media (max-width: 1200px) {
    .mobile__search-icon {
        width: 230px;
    }
}
@media (max-width: 1024px) {
    .mobile {
        &__search-icon {
            display: none;
        }
        &__menu {
            width: 320px;
            &-search {
                display: block;
                padding: 10px;
                display: flex;
                align-items: center;
                .mobile__search-icon {
                    display: block;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
                input {
                    color: #fff;
                }
            }
            &__item-children {
                .menu__item-child {
                    margin-left: 0;
                    
                }
            }
            &-items {
                padding: 0 8px 10px 10px!important;
            }
            &-footer {
                display: flex;
                flex-direction: column;
                padding: 10px 10px 30px 10px;
                gap: 20px; 
            }
            &-arrow {
                width: 82px;
            }
            &-btns {
                padding: 10px;
            }
            &-currency {
                display: flex;
            }
            
        }
        &-menu {
            height: 19px;
            width: 20px;
            &.arrow {
                width: 18px;
                height: 13px;
            }
        }
    }
    .menu__item-link {
        span {
            width: auto !important;
        }
        
        .icon-container {
            width: 100% !important;
        }
    }
    .menu-title {
        display: none;
    }
   
}
