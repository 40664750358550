//Settle animation or transition delay 
@mixin delay($rule, $number, $value) {
    @for $i from 1 to ($number + 1) {
      &:nth-child(#{$i}) {
        -webkit-#{$rule}-delay: (#{$i*$value});
        #{$rule}-delay: (#{$i*$value});
      }
    }
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1}
}

.menu {
    background: #000000;
    .container {
        height: 100%;
    }
    &__content {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #ffffff;
        width: 100%;
    }

    &__items {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        // gap: 20px;
    }

    &__item {
        display: flex;
        flex-flow: column;
        cursor: pointer;
        position: relative;
        text-align: left;
        z-index: 10;
        transition: all 0.2s;
        align-items: flex-start;
        width: 100%;
        &-children {
            opacity: 0;
            height: 0;
            visibility: hidden;
            width: 310px;
            transform: translateX(-100%);
            min-width: max-content;
            background: var(--dark-grey, #161616);
            color: #a9a9a9;
            text-align: left;
            overflow: auto;
            box-shadow: 0 0 10px rgb(0 0 0 / 10%);
            transition: transform 0.2s;
            position: absolute;
            left: 0px;
            top: 0;
            &-wrapper {
                position: absolute;
                left: 380px;
                top: 0;
                width: 0;
                right: 0;
                height: 100%;
                overflow: hidden;
                &.active {
                    width: 320px;
                }
            }
            &.active {
                opacity: 1;
                height: 100%;
                transform: translateX(0);
                visibility: visible;
                display: flex;
                padding: 40px 20px;
                flex-direction: column;
                align-items: flex-start;
                .menu__item-child {
                    padding: 0 10px; 
                    width: 100%;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                }
                .menu__item-childlink {
                    padding: 16px 0;
                }

            }
        }
        &-child {
            cursor: pointer;
            transition: background-color .2s;
            color: #fff;
            &:hover {
                background-color: #d5ba6c;
            }
        }
        &-childlink {
            width: 90%;
            display: block;
            margin-left: 5%;
        }
        &:first-child {
            .menu__item-link {
                //padding-left: 0px;
                justify-content: flex-start;
            }
        }
        &:last-child {
            .menu__item-link {
            }
        }
       
    }

    &__item-link {
        padding: 0 10px;
        font-weight: 400;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        font-size: 13px;
        color: #fff;
        line-height: normal;
        flex: 1 0 0;
        &:hover {
            color: #d5ba6c;
            background: rgba(255, 255, 255, 0.10);
        }
    }
}

.menu__items {
    overflow-y: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}
.menu__item-children-header {
    display: none;
}

@media (max-width: 1024px) {
    .menu {
        display: none;
    }
    .mobile__menu-items .menu__item-children.active {
        position: relative;
        opacity: 1;
        height: 100%;
        width: 100%;
        left: 0;
        padding: 10px;
        .menu__item-child {
            padding: 0px 10px; 
            width: 100%;
            font-weight: 300;
        }
    }
    .menu__item-link {
        //padding: 10px;
    }
    .menu__item-children-wrapper {
        left: 0;
        top:0;
        width: 320px;
        display: none;
        background: var(--dark-grey, #161616);
        &.active {
            display: block;
            height: 100%;
            z-index: 100;
        }
    }
    .menu__item-children-header {
        display: flex;
        align-items: center;
        height: 51px;
        padding: 16px 10px;
        border-bottom: 1px solid rgba(246, 244, 234, 0.20);
    }
    .mobile__header-title {
        font-size: 14px;
        color: #D5BA6C;
        text-align: center;
        width: 135px;
    }
}


.hiddenscroll {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}

  


.menu__item-children.active .menu__item-child {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    @include delay(animation, 10, 0.1s);
}
