
    .category .gallery__slider {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1200px) {
        .category .gallery__slider {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (max-width: 768px) {
        .category .gallery__slider {
            grid-template-columns: repeat(2, 1fr);
        }
    }
