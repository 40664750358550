*,
*::after,
*::before {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
html {
    box-sizing: border-box;
}
ul[class],
ol[class] {
    padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    font-size: inherit;
}

ul[class] {
    list-style: none;
}

input,
button,
textarea,
select {
    font: inherit;
}

a {
    text-decoration: none;
    color: inherit;
}

input[type="radio"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    user-select: none;
    display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield !important;
}
input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}