.header {
    &__content {
        display: flex;
        //height: 64px;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }

    &__leftside {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
    }

    &__leftside-item {
        // &:nth-child(3),
        // &:nth-child(4),
        // &:nth-child(5) {
        //     .header__leftside-link {
        //         display: block;
        //         width: 13px;
        //         height: 16px;
        //     }
        // }
    }

    &__leftside-items {
        display: flex;
    }
    &__rightside-item.bag {
        position: relative;
    }

    &__leftside-item--text {
        padding-right: 30px;
    }
    &__leftside-item:not(:last-child) {
        padding-right: 40px;
        display: flex;
        align-items: center;
    }

    &__leftside-link {
        transition: all 0.2s;
        color: #000;
        display: flex;
        align-items: center;
        &:hover {
            color: #d5ba6c;
        }
    }

    &__logo {
        // position: absolute;
        // left: 0;
        // right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        // max-width: 95px;
        // max-height: 45px;
        img {
            // width: 90px;
            // height: 46px;
            padding-top: 5px;
        }
    }

    &__rightside {
        display: flex;
        position: relative;
        align-items: center;
        gap: 30px;
    }
    &-links {
        transition: all 0.2s;
        font-size: 15px;
        font-weight: 400;
        &:hover {
            color: #D5BA6C;
        }
    }

    &__rightside-items {
        display: flex;
    }

    &__rightside-item {
        margin-right: 30px;
    }

    &__rightside-link {
        transition: all 0.2s;
        width: 16px;
        height: 16px;
        max-width: 16px;
        display: block;
    }
    .favorite-item {
        position: relative;
    }

    &__language-items {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        position: relative;
        display: flex;
    }

    &__language-item {
        padding-left: 20px;
    }

    &__language-link {
        transition: all 0.2s;
        &:hover {
            color: #d5ba6c;
        }
    }
    &__language-item.active-language {
        color: #d5ba6c;
    }
    &__right-card {
        fill: #F6F4EA;
       // margin-left: 20px;
    }
}

.mobile {
    &__header {
        display: block;
        background: #000;
        &.modal {
            height: 46px;
        }
        .header__language-items {
            margin-left: 20px;
        }
        .mobile__right-favorie {
            position: relative;
            .cart__favorite {
                right: -8px;
            }
        }
        .header__right-card {
            position: relative;
            .cart__cantity {
                right: -12px;
            }
        }
    }
    &__content {
        display: flex;
        opacity: 1;
        flex-direction: column;
        height: 100%;
        min-height: 0;
        width: 380px;
        background: #000;
        padding: 40px 0 0 20px;
        position: fixed;
        left: 0;
        pointer-events: initial;
        top: 0;
        transition: transform 0.4s;
        transform: translateX(-100%);
        
    }
    &__menu-social {
        position: relative;
        margin-top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        .mobile__items {
            display: flex;
            padding: 40px 0 28px 0;
            justify-content: center;
            align-items: center;
            gap: 40px;
            align-self: stretch;
        }
    }
    
    &__header-item {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    &__header-content {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &.hide {
            display: none;
        }
    }
    &__menu-item {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        width: 340px;
        
    }
    &__header-menu {
        cursor: pointer;
        z-index: 20;
        display: flex;
        align-items: center;
        gap: 13px;
        transition: all 0.2s;
        &:hover {
            .menu-title {
                color: #D5BA6C;
            }
            .icon {
                stroke: #D5BA6C;
            }
        }
    }
    &__header-logo {
        text-align: center;
        width: 200px;
        left: 50%;
            transform: translateX(-50%);
            top: 20px;
            position: absolute;
            height: 25px;
        img {
            height: 25px;
            width: 200px;
            object-fit: cover;
            
            
            

        }
        &.mini {
            img {
                margin-left: 0;
                width: auto;
                height: auto;
                max-height: 100%;
            }
        }
    }
    &__language-items {
        display: flex;
        margin-left: 24px;
    }
    &__language-item {
        font-size: 13px;
    }
    &__language-item:not(:last-child) {
        padding-right: 15px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 15px;
            background-color: #000;
            right: 6px;
            top: 2px;
        }
    }
    &__language-item.active-language {
        color: #d5ba6c;
    }

    &__header-right {
        //margin-left: auto;
        display: flex;
        align-items: center;
        gap: 40px;
    }

    &__right-cart {
        padding-left: 20px;
    }
    &__auth {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: var(--lea-gold, #D5BA6C);
        color:#F6F4EA;
        border: 1px solid transparent;
        transition: all 0.2s;
        cursor: pointer;
        padding: 14px 21px 14px 20px;
        font-weight: 500;
        letter-spacing: 0.65px;
        font-size: 13px;
        line-height: normal;
        &:hover {
            background: transparent;
            color: #D5BA6C;
            border: 1px solid #D5BA6C;
            transition: all 0.2s;
        }
    }
}

.desktop__header-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 20px;
}
.menu-span {
    text-transform: uppercase;
    background: var(--lea-gold-gradient, linear-gradient(90deg, #C1AF7B 0%, #FFFCB3 22.56%, #F9EE8E 39.75%, #AE8D48 57.60%, #9A7333 73.61%, #C4A756 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: auto;
    font-size: 20px;
    font-weight: 500;
    flex: 1 0 0;
}
.logout {
    &__btns {
        display: flex;
        justify-content: center;
    }
    &__btn-logout {
        height: 52px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        color: #fff;
        width: 190px;
        text-transform: uppercase;
        margin-left: 20px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        border: 1px solid transparent;
        transition: all 0.2s;
        margin-right: 20px;
        &:hover {
            border: 1px solid #000;
            background: #fff;
            color: #000;
            transition: all 0.2s;
        }
    }
    &__btn-cancel {
        cursor: pointer;
        color: #fff;
        background: #d5ba6c;
        border: 1px solid transparent;
        height: 52px;
        width: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transition: all 0.2s;
        white-space: nowrap;
        padding: 0 24px;
        &:hover {
            transition: all 0.2s;
            color: #d5ba6c;
            background: #fff;
            border: 1px solid #d5ba6c;
        }
    }
}

//~ ICONS

.facebook,
.instagram,
.twitter {
    width: 100%;
    height: 100%;
    fill: #000;
    max-height: 16px;
    max-width: 16px;
}

.icon.search,
.icon.favorite,
.icon.bag,
.icon.user {
    width: 100%;
    height: 100%;
    fill: #F6F4EA;
    transition: all 0.2s;
}
.icon.search {
    &:hover {
        fill: #d5ba6c;
    }
}
.favorite-mobile {
    width: 16px;
    height: 16px;
    fill: #F6F4EA;
}
.bag-mobile {
    width: 16px;
    height: 16px;
}

.search__popup.active {
    transition: all 0.2s;
    visibility: visible;
    opacity: 1;
    width: calc(95% - 30px);
    position: relative;
}
.search {
    &__popup {
        //background: #fff;
        // width: 100%;
        // z-index: 999999;
        // position: absolute;
        // top: 0;
        // right: 250px ;
        transition: all 0.2s;
        opacity: 0;
        visibility: hidden;
        width: 0;
       // position: absolute;


    }
    &__popup-input {
        border: none !important;
        border-bottom: 1px solid #a9a9a9 !important;
        width: 100%;
        height: 25px !important;
        padding: 0 10px 5px 5px!important;
        background: transparent;
        color: #a9a9a9 !important;
        margin: 0 !important;
    }
    &__popup-content {
        position: relative;
       
        //padding-left: 32px;
        // &::before {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     top: 12px;
        //     background-repeat: no-repeat;
        //     width: 17px;
        //     height: 17px;
        //     background-image: url(../../assets/images/icons/popup-search.svg);
        // }
    }

    &__popup-close {
        position: absolute;
        right: -2px;
        height: 12px;
        z-index: 2;
        width: 12px;
        top: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
body .header,
body .mobile__menu {
    [class^=' css'][class$='ValueContainer'] {
        padding: 0;
    }
    [class^=' css'][class$='option'] {
        // padding-left: 5px;
        text-align: center;
    }
    [class^=' css'][class$='control'] {
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: all 0.2s;
        background: none;
    }
    [class^=' css'][class$='control'] {
        border: none;
        box-shadow: none;
        &::after {
            content: "";
            transition: all 0.2s;
            position: absolute;
            width: 6px;
            height: 4px;
            background-repeat: no-repeat;
            background-image: url(../../assets/images/icons/language-arrow.svg);
            right: 0;
            top: 18px;
        }
        &:hover {
            color: #d5ba6c;
            transition: all 0.2s;
            [class^=' css'][class$='placeholder']{
                color: #d5ba6c;
                transition: all 0.2s;
            }
        }
        &:hover::after {
            transition: all 0.2s;
            background-image: url(../../assets/images/icons/language-arrow-hov.svg);
        }
        cursor: pointer;
 

        [class^=' css'][class$='option']:active {
            background-color: #d5ba6c;
            color: #fff;
        }
    }
    #react-select-2-listbox {
        left: -22px;
        border-radius: 0;
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
        }
    }
    [class^=' css'][class$='control']:hover {
        border: none;
    }
    .mobile__header-right.small {
        [class^=' css'][class$='placeholder']{
            color: #fff;
            transition: all 0.2s;
            text-transform: capitalize;
        }
    }
    [class^=' css'][class$='placeholder']{
        color: #000;
        transition: all 0.2s;
        text-transform: capitalize;
    }
    [class^=' css'][class$='control']:hover {
        border: none;
        box-shadow: none;
    }
    [class^=' css'][class$='indicatorSeparator'] {
        display: none;
    }
    [class^=' css'][class$='indicatorContainer'] {
        display: none;
    }

    [class^=' css'][class$='menu']  {
        width: 100px;
    }
    [class^=' css'][class$='MenuList'] {
        padding: 0;
        right: 10px;
        
    }
    [class^=' css'][class$='option'] {
        background-color: #fff;
        color: #a9a9a9;
        cursor: pointer;
    }
    [class^=' css'][class$='option']:active {
        background-color: #d5ba6c;
        // padding-left: 5px;
    }
    [class^=' css'][class$='menu'] {
        border-radius: 0;
        left: -5px;
        margin: 0;
        width: 90px;
        position: absolute;
        right: 0;
        box-shadow: none;
    }

    [class^=' css'][class$='control']  {
        [class^=' css'][class$='singleValue'] {
            transition: all 0.2s;
            color: #F6F4EA;
        }
        &:hover {
            [class^=' css'][class$='singleValue'] {
            color: #d5ba6c;
            }
        }
    }
    [class^=' css'][class$='option'] {
        transition: all 0.2s;
    }

    [class^=' css'][class$='option']:hover {
        background-color: #d5ba6c;
        color: #fff;
        transition: all 0.2s;
    }
    [class^=' css'][class$='option']:active {
        background-color: #d5ba6c;
        color: #fff;
    }

    [class^=' css'][class$='control']{
        &:hover {
             [class^=' css'][class$='ValueContainer'] {
                [class^=' css'][class$='singleValue']  {
                    color: var(--golden);
                }
            }
        }
    }
    [class^=' css'][class$='control'] {
        &::after {
            content: "";
            transition: all 0.2s;
            position: absolute;
            width: 6px;
            height: 4px;
            background-repeat: no-repeat;
            background-image: url(../../assets/images/icons/language-arrow.svg);
            right: -15px;
            top: 18px;
        }
        &:hover {
            color: #d5ba6c;
            transition: all 0.2s;
             [class^=' css'][class$='placeholder']{
                color: #d5ba6c;
                transition: all 0.2s;
            }
        }
        &:hover::after {
            transition: all 0.2s;
            background-image: url(../../assets/images/icons/language-arrow-hov.svg);
        }
        cursor: pointer;
        margin-right: 15px;
        min-width: 35px;
    }
}

body .header [class^=" css"][class$="control"] [class^=" css"][class$="singleValue"][class$="singleValue"] {
    color: #000;
}
body .mobile__menu {
    [class^=' css'][class$='menu'] {
        width: 54px;
        padding-left: 0;
        left: 0;
        top: 30px;
    }
    [class^=' css'][class$='option']
    {
        padding-left: 10px;
    }
    
    [class^=' css'][class$='ValueContainer'] {
        margin-right: -3px;
    }
    .mobile__header-right.small [class^=" css"][class$=placeholder] {
        margin: 0;
        margin-left: 12px;
    }
}
.header__language-items {
    transition: all 0.2s;
    z-index: 20;
}
.close-search {
    height: 9px;
    width: 9px;
    cursor: pointer;
    fill: #a9a9a9;

}
body .header [class^=" css"][class$=control]::after {
    background-image: url('../../assets/images/icons/language-arrow-black.svg')
}
@media (max-width: 1024px) {
    [class^=' css'][class$='menu'] {
        position: absolute;
        top: 20px;
    }
    .header {
        display: none;
    }
    .mobile {
        &__content {
            padding: 0;
            width: 320px;
        }
        &__header {
            &-content {
                padding: 16px 0;
            }
            &-content.hide {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(246, 244, 234, 0.20);
                padding: 16px 10px;
                align-items: center;
            }
            &-menu {
                flex: 1;
                height: 16px;
            }
            &-right {
                flex: 1;
                justify-content: flex-end;
                gap: 20px;
                &.small {
                    width: 82px;
                    margin-left: 0;
                    justify-content: flex-end;
                    flex: unset;
                }
            }
            &-logo {
                position: relative;
                transform: none;
                left: auto;
                top: auto;
                width: 110px;
                height: 13.75px;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
            
            .header__language-items {
                margin-left: 0;
            }
        }
        &__menu-item {
            width: 300px;
        }
        &__menu-social .mobile__items {
            padding: 40px 0 20px 0;
        }
       
    }
    .desktop__header-content {
        display: none;
    }
    .menu-span_mobile {
        margin-right: 0;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 500;
        width: 135px;
        text-align: center;
        line-height: normal;
        color:#D5BA6C;
        text-transform: uppercase;
    }

    body .mobile__menu [class^=" css"][class$=control] {
        min-height: 18px;
        min-width: 20px;
        
    }
    body .mobile__menu [class^=" css"][class$=control]::after {
        top: 7px;
    }

}

@media (max-width: 968px) {
    .logout__btn-logout,
    .logout__btn-cancel {
        width: 130px;
    }

}


