.bag-popup {
    position: fixed;
    background: #fff;
    top: 60px;
    right: 50px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    z-index: 9;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
}
.bag-popup.fixed {
    top: 0;
}
.bag-popup__color {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        left: -10px;
        top: -4px;
        height: 24px;
        width: 1px;
        background: #f3f3f3;
    }
}
.bag-popup__details {
    display: flex;
    align-items: center;
}
.bag-popup__main {
    max-height: 240px;
    overflow-y: auto;
    width: 356px;
}
.close-popup {
    width: 15px;
    height: 15px;
}
.bag-popup-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
}
.bag-popup.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
}

.bag-popup__content {
    padding: 0px 0 30px 20px;
    display: flex;
}
.bag-popup__content:last-child {
    padding: 10px 20px;
}

.bag-popup__image {
    cursor: pointer;
    margin-right: 20px;
    width: 84px;
    height: 100px;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.bag-popup__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.05em;
    padding-bottom: 2px;
    color: #000;
}
.bag-popup__price {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    padding-bottom: 26px;
    color: #000;
}
.bag-popup__size {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #474747;
}

.bag-popu__btn-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 14px 86px;
    white-space: nowrap;
}
.bag-popup__counter {
    border: 1px solid #f3f3f3 !important;
    outline: #d5ba6c !important;
    transition: all 0.3s;
    height: 32px;
    width: 42px;
    text-align: center;
    -webkit-appearance: none;
}

.bag-popup__input {
    display: flex;
}
