:root {
    --black: #000000;
    --blackTwo: #131313;
    --grayNight: #474747;
    --golden: #d5ba6c;
    --gray: #A9A9A9;
    --grayBg: #F3F3F3;
    --grayBtn: #E6E6E6;
    --white: #FFFFFF;
    --red: red;
}