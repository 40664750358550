.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: all .5s;
    overflow-y: auto;
    padding: 20px 10px;
    backdrop-filter: blur(2.5px);
}
.overlay.active{
    opacity: 1;
    pointer-events: auto;
    transition: all .5s;
    z-index: 9999;
    overflow-y: hidden;
    overflow-x: hidden;
}