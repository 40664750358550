:root{
    scrollbar-face-color: #f2f1ec; /* Firefox 63 compatibility */
    scrollbar-track-color: #d5ba6c; /* Firefox 63 compatibility */
    scrollbar-color:#d5ba6c #f2f1ec ;
    scrollbar-width: thin;
  }

body {
    font-family: "lato", sans-serif;
    font-weight: 400;
    color: #000000;
    overflow-x: hidden;
    

    ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        
    }

    ::-webkit-scrollbar-track {
        background: #f2f1ec;
    }

    ::-webkit-scrollbar-thumb {
        background: #d5ba6c;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #d5ba6c !important;
    }

    *:focus-visible {
        border-radius: 0 !important;
    }

    .fancybox__progress {
        background: #d5ba6c;

    }

    .Toastify__toast-body,
    .Toastify__toast {
        padding: 0;
        border-radius: 0;
    }

    .Toastify__close-button {
        display: none;
    }

    .Toastify__progress-bar-theme--light {
        background: #d5ba6c;
    }

    .Toastify__toast-container--top-right {
        width: 360px;
        height: auto;
    }

    .succes__popup {
        padding: 20px;
        width: 360px;
        height: 100px;
    }

    .succes__popup-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: 0.05em;
        color: #008c51;
        margin-bottom: 2px;
    }

    .succes__popup-id {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 2px;
    -webkit-appearance: none;
   
}

::-webkit-scrollbar-track {
    background: #f2f1ec;
}

::-webkit-scrollbar-thumb {
    background: #d5ba6c;
}

::-webkit-scrollbar-thumb:hover {
    background: #d5ba6c;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
    margin-top: auto;
}

body.overlay {
    overflow: hidden;
}

.container {
    max-width: 1320px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
}

.registration__name-input {
    position: relative;
    margin-bottom: 20px;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 52px;
    border: 1px solid #a9a9a9;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #474747;
    transition: all 0.2s;
}

.registration__name-input:focus {
    border: 1px solid #474747;
    border-radius: 0;
    outline: none;
    transition: all 0.2s;
}

//IOS
input, textarea {
    border-radius: 0;
}
select {
    display: none;
}
.registration__name-input::placeholder {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #a9a9a9;
}

input[type="text"],
input[type="password"] {
    margin-bottom: 25px;
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 52px;
    border: 1px solid #a9a9a9;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #474747;
    transition: all 0.2s;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border: 1px solid #474747;
    border-radius: 0;
    outline: none;
    transition: all 0.2s;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #a9a9a9;
}

.disabled {
    opacity: 0.1;
    pointer-events: none;
}

.icon {
    transition: all ease-in-out 0.2s;
}

.icon:hover {
    fill: #d5ba6c;
    transition: all ease-in-out 0.2s;
}

.error-mesage {
    color: #e80000;
    margin-top: -20px;
    position: absolute;
    font-size: 12px;
    font-weight: 400;
}

.pagination {
    grid-column: 2;
    margin-top: 50px;

    .pagination-arrow {
        width: 20px;
        height: 20px;
        z-index: 2;
        transition: all 0.2s ease-in-out;
        fill: #000000;

        &:hover {
            fill: #000000;
        }
    }

    &__link.pagination__dots {
        background: #fff;
        border: 1px solid #f3f1f0;
        color: #a9a9a9;
    }

    &__items {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__link.active-page {
        background: #d5ba6c;
        color: #ffffff;
        cursor: default;
    }

    &__link.disable {
        pointer-events: none;
    }

    &__link.none {
        display: none;
    }

    &__link {
        background: #f3f3f3;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
            background: #d5ba6c;
            color: #ffffff;
            transition: all 0.2s;

            .pagination-arrow {
                transition: all 0.2s ease-in-out;
                fill: #fff;
            }
        }
    }

    &__link:not(:last-child) {
        margin-right: 20px;
    }
}

.instagram-social,
.facebook-social {
    width: 100%;
    height: 100%;
    fill: #474747;
}

.breadcrumbs {
   

    &__item {
        display: flex;
    }

    &__items:not(:last-child) {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #474747;
        position: relative;
        display: inline-block;
        padding-right: 18px;

        &::after {
            content: "";
            position: absolute;
            background-image: url(../../assets/images/icons/bread-arrow.svg);
            width: 5px;
            height: 7px;
            top: 3px;
            right: 5px;
        }
    }

    &__items:last-child {
        color: #a9a9a9;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        pointer-events: none;
    }

    &__content {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.plus-btn,
.minus-btn,
.plus-btn.product__plus-btn,
.minus-btn.product__minus-btn {
    transition: all 0.2;

    &:hover {
        color: #d5ba6c;
        transition: all 0.2s;
    }
}

.minus-btn,
.plus-btn {
    width: 30px;
    height: 32px;
    background: #f3f3f3;
    border: 1px solid transparent;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #a9a9a9;
}

.minus-btn.dis {
    pointer-events: none;
}

.cart__cantity {
    font-size: 10px;
    width: 15px;
    height: 15px;
    background: #d5ba6c;
    position: absolute;
    top: -5px;
    right: -10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    box-sizing: revert;
}
.cart__favorite.full,
.cart__cantity.full {
    padding: 2px;
    top: -9px;
    right: -13px;
}

.cart__favorite {
    font-size: 10px;
    width: 15px;
    height: 15px;
    background: #d5ba6c;
    position: absolute;
    top: -5px;
    right: -10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.cart__favorite.full,
.cart__cantity.full {
    width: 20px;
    height: 20px;
    top: -10px;
    right: -15px;
}

.icon.gal-bag {
    width: 20px;
    height: 20px;
    transition: all 0.2s;

    &:hover {
        fill: #fff;
        transition: all 0.2s;
    }
}

.icon.fav {
    width: 20px;
    height: 20px;
}

.icon.fav-active {
    width: 20px;
    height: 20px;
    fill: #d5ba6c;
}

.icon.gal-bag-active {
    width: 20px;
    height: 20px;
    fill: #d5ba6c;
}

.loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 999;
    background: #000000;
}

@media (max-width: 968px) {

    input[type="text"],
    input[type="password"] {
        margin-bottom: 26px;
        padding: 14px 20px 18px 10px;
        width: 100%;
        height: 44px;
    }

    .registration__name-input {
        height: 44px;
        padding-left: 10px;
    }

    .profile__lastname-input {
        padding: 14px 20px 18px 10px !important;
        height: 44px !important;
    }
}

@media (max-width: 768px) {
    .icon.gal-bag {
        width: 15px;
        height: 15px;
    }

    .icon.fav {
        width: 15px;
        height: 15px;
    }

    .icon.gal-bag-active {
        width: 15px;
        height: 15px;
    }

    .icon.fav-active {
        width: 15px;
        height: 15px;
        fill: #d5ba6c;
    }

    body {
        .Toastify__toast-container--top-right {
            width: 100%;
            height: auto;
        }
    }

    .pagination {
        margin-top: 30px;
    }
}

.no-document-scroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

button[disabled] {
	cursor: wait;
}