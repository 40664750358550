.footer {
    background: #000000;

    &__content {
        display: flex;
        padding-top: 71px;
        padding-bottom: 71px;
        color: #ffffff;
        position: relative;
        flex-wrap: wrap;
    }

    &__info {
        max-width: 330px;
        margin-right: auto;
    }

    &__logo-link {
        margin-bottom: 21px;
        img {
            width: 212px;
            height: 27px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        padding: 29px 0;
    }
    &__text-top {
        padding-top: 21px;
        font-weight: 400;
    }
    &__pay-link:not(:last-child) {
        margin-right: 28px;
    }
    &__pay {
        display: flex;
    }
    &__category {
        text-transform: uppercase;
    }
    &__category-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 10px;
        padding-top: 47px;
    }
    &__category-content {
        display: flex;
        max-width: 286px;
        width: 286px;
    }
    &__items-left {
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        margin-right: 0;
        width: 100%;
        // display: flex;
        // flex-direction: row;
        // flex-wrap: wrap;
        display: block;
        column-count: 2;
        max-height: 250px;
        @media (max-width: 1200px) {
            max-height: 280px;
            
        }
    }

    &__item {
        padding-bottom: 5px;
        font-weight: 500;
        break-inside: avoid;
    }

    &__media-phone {
        letter-spacing: 0.05em;
    }

    &__link-left {
        cursor: pointer;
        transition: all 0.3s;
        opacity: 0.6;
        word-wrap: break-word;
        &:hover {
            color: #d5ba6c;
            opacity: 1;
        }
    }

    &__items-right {
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
    }

    &__item-right {
        margin-bottom: 5px;
    }

    &__link-right {
        transition: all 0.3s;
        opacity: 0.6;
        &:hover {
            color: #d5ba6c;
            opacity: 1;
        }
    }

    &__services {
        padding-top: 47px;
        padding-left: 50px;
    }

    &__services-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    &__services-items {
        font-weight: 500;
        font-size: 13px;
        line-height: 27px;
        text-transform: uppercase;
        margin-bottom: 13px;
    }

    &__services-item {
        margin-bottom: 5px;
    }

    &__services-link {
        cursor: pointer;
        display: inline-block;
        transition: all 0.3s;
        opacity: 0.6;
        &:hover {
            color: #d5ba6c;
            opacity: 1;
        }
    }

    &__services-subscribe {
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.3);
        transition: all 0.3s;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            transition: all 0.3s;
            background-image: url(../../assets/images/icons/subscribe-arrow.svg);
            width: 10px;
            height: 10px;
            right: 10px;
            top: 13px;
        }
        &:hover {
            background: #fff;
            border: 1px solid transparent;
            color: #000;
            transition: all 0.3s;
            &::after {
                content: "";
                position: absolute;
                background-image: url(../../assets/images/icons/subscribe-arrow-hover.svg);
                width: 10px;
                height: 10px;
                right: 10px;
                top: 13px;
                transition: all 0.3s;
            }
        }
    }

    &__service-btn {
        padding: 11px 36px 11px 16px;
        position: relative;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transition: all 0.3s;
    }
    &__about {
        // margin-left: 75px;
        display: flex;
    }
    &__phone:not(:last-of-type) {
        margin-bottom: 10px;
    }
    &__phones {
        display: flex;
        position: absolute;
        top: 70px;
        right: 0;
    }

    &__phone-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        text-transform: uppercase;
        padding-right: 5px;
    }

    &__phone-list {
        display: flex;
        flex-flow: column;
    }

    &__phone-number {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        transition: all 0.3s;

        :hover {
            transition: all 0.3s;
            color: var(--golden);
        }
    }

    &__phone-number span {
        font-weight: 700;
        padding-left: 5px;
    }

    &__about-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    &__about-items {
        font-weight: 500;
        font-size: 13px;
        line-height: 27px;
        text-transform: uppercase;
    }

    &__about-item {
        margin-bottom: 5px;
    }

    &__about-link {
        transition: all 0.3s;
        opacity: 0.6;
        &:hover {
            color: #d5ba6c;
            opacity: 1;
        }
    }

    &__social {
        // margin-top: 50px;
        width: 100%;
        justify-content: flex-end;
        display: flex;
    }

    &__social-link {
        width: 16px;
        height: 18px;
        display: block;
    }
    &__social-link:not(:last-child) {
        margin-right: 40px;
    }
    &__media-content {
        display: none;
    }
    &__media-link {
        color: #fff;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            color: #d5ba6c;
            transition: all 0.3s;
        }
    }
    &__media-links-left ,
    &__media-links-right {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        max-width: 50%;
        text-align: left;
    }
    &__media-item {
        margin-bottom: 28px;
    }
    &__media-link:not(:last-child) {
        margin-bottom: 28px;
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
}

.visa,
.paypal,
.master {
    width: 33px;
    height: 29px;
    fill: #fff;
}

.wallet {
    width: 29px;
    height: 29px;
    fill: #fff;
}

.instagram-wh,
.facebook-wh,
.twitter-wh {
    width: 100%;
    height: 100%;
    fill: #fff;
}
.footer {
    &__mobile_links {
        display: none;
        font-weight: 600;
        flex-flow: column;
        font-size: 13px;
        line-height: 1.2;
        margin: 20px 0;
        text-transform: uppercase;
    }
    &__mobile_item {
        display: flex;
        padding: 8px 0;
        &:hover {
            color: #d5ba6c;
        }
    }
}

@media (max-width: 1024px) {
    .footer {
        &__mobile_links {
            display: flex;
        }
        &__content {
            display: block;
            padding-top: 35px;
            padding-bottom: 35px;
        }
        &.footer__content {
            padding-top: 60px;
            padding-bottom: 20px;
        }
        &__category,
        &__services,
        &__about-items,
        &__about-title,
        &__phones {
            display: none;
            &_mobile {
                .footer__phones {
                    display: flex;
                    position: static;
                    margin: 20px 0;
                    .footer__phone-list{
                        flex-flow: row;
                    }
                }
      
            }
        }
        

    

        // &__pay-link:not(:last-child) {
        //     padding-right: 64px;
        // }
        &__about {
            position: relative;
            margin-left: 0;
        }
        &__social {
            margin-top: 20px;
            justify-content: flex-start;
            // position: absolute;
            // top: 110%;
            // left: 50%;
            // margin-right: -50%;
            // transform: translate(-50%, -50%);
        }
        &__media-content {
            text-align: center;
            display: block;
        }
        &__media-bot {
            display: flex;
            justify-content: space-between;
        }
        &__media-top {
            display: flex;
            justify-content: space-between;
        }
        &__media-links {
            display: flex;
            justify-content: space-around;
            text-align: center;
            margin: 20px 0;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 34px;
        }
        &__info {
            max-width: 417px;
            margin-right: 214px;
        }
        &__text {
            padding-top: 20px;
        }
        &__media-sunscribe {
            display: block;
            padding: 14px 0px;
            border: 1px solid #fff;
            max-width: 340px;
            margin: 0 auto;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            white-space: nowrap;
            margin-bottom: 13px;
            text-align: center;
        }
        &__media-phone {
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}


@media (max-width: 768px) {
    .footer {
        &__content {
            padding: 30px 0;
        }
        &__info {
            margin-right: 0;
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
        }
    }
    // .footer__pay {
    //     justify-content: space-between;
    // }
}
