.product {
    &__content {
        display: flex;
        margin-top: 34px;
        margin-bottom: 56px;
        justify-content: space-between;
        gap: 30px;
        position: relative;
    }

    &__slider {
        position: relative;
        width: 100%;
        height: 100%; 
        max-width: 640px;
        .gallery__discount {
            margin-top: 20px;
            margin-left: 20px;
            padding: 12px;
            font-size: 22px;
        }
    }
    .slick-track {
        display: flex;
        //padding-top: 10px;
    }
    .slick-list {
        overflow-x: hidden;
        max-width: 640px;
    }
    &__info {
        width: 100%;
    }

    &__article {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #a9a9a9;
    }

    &__number {
        padding-left: 5px;
        color: #000000;
    }

    &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        padding-top: 6px;
    }
    &__favorite-content.active {
        .product__favorite-text {
            color: #000;
            transition: all 0.3s;
        }
        .favorite-icon {
            fill: #000;
            transition: all 0.3s;
        }
    }
    &__description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-top: 10px;
    }

    &__size {
        padding-top: 20px;
    }

    &__size-title {
        padding-bottom: 10px;
        font-weight: 600!important;
        font-size: 16px!important;
        line-height: 19px!important;
        font-family: "lato", sans-serif;
        text-transform: uppercase;
        color: #000000;
    }

    &__size-items {
        margin-bottom: 10px;
    }

    &__size-text {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #000000;
    }

    &__color {
        padding-top: 20px;
    }

    &__color-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-bottom: 10px;
    }

    &__color-items {
        padding-bottom: 48px;
    }

    &__price {
        margin-top: 20px;
        font-weight: 800;
        font-size: 26px;
        line-height: 45px;
        text-transform: uppercase;
        color: #131313;
        padding-bottom: 10px;
        display: flex;
        align-items: flex-end;
        gap: 8px;
        &.sale {
            color: var(--red);
        }
    }
    .category__color-items {
        margin-bottom: 0;
    }
    &__error {
        margin-top: 5px;
        color: #e80000;
        font-size: 12px;
        line-height: 14px;
    }
    &__valute {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding-left: 10px;
    }

    &__stock {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding-left: 5px;
        color: #a9a9a9;
        text-transform: none;
        padding-bottom: 10px;
    }

    &__buy {
       // padding-bottom: 20px;
    }

    &__buy-content {
        display: flex;
    }
    &__input {
        padding-right: 20px;
    }

    &__price-buy {
        cursor: pointer;
        margin-right: 20px;
        background: #d5ba6c;
        color: #ffffff;
        border: 1px solid #d5ba6c;
        transition: all 0.3s;
        min-width: 190px;
        width: 100%;
        justify-content: center;
        display: flex;
        &:hover {
            background: #fff;
            color: #000;
            border: 1px solid #000;
            transition: all 0.3s;
        }
    }
    &__buy-text {
        padding: 16px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    &__price-add {
        cursor: pointer;
        color: #ffffff;
        background: #000;
        border: 1px solid #000;
        transition: all 0.3s;
        min-width: 190px;
        width: 100%;
        margin-right: 20px;
        width: calc(50% - 93px);
        &:hover {
            transition: all 0.3s;
            color: #000;
            background: #fff;
        }
    }
    &__disabled {
        background: var(--grayBtn);
        color: var(--white);
        border: 1px solid var(--grayBtn);
    }
    .minus-btn,
    .plus-btn {
        width: 48px;
        height: 52px;
        background: #f3f3f3;
        border: 1px solid transparent;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.05em;
        font-size: 22px;
    }
    .bag-popup__counter {
        transition: all 0.3s;
        height: 52px;
        width: 50px;
        text-align: center;
        -webkit-appearance: none;
        font-size: 22px;
    }
    .bag-popup__input {
        margin-right: 20px;
    }

    &__popup {
        display: none;
        transition: all 0.3s;
        z-index: 99999;
        max-width: 918px;
        margin: 20px 10px;
        height: 100%;
        position: absolute;
    }
    &__popup.active__product-popup {
        display: flex;
        align-items: center;
        transition: all 0.3s;
        margin: 0 10px;
    }
    &__popup-image {
        object-fit: cover;
    }
    &__popup-content {
        display: flex;
        background: #fff;
        position: relative;
        margin: 20px 0;
    }

    &__popup-close {
        position: absolute;
        background: #000;
        height: 42px;
        width: 42px;
        z-index: 2;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            background: #fff;
            .close-product {
                fill: #000;
            }
        }
    }
    .icon.close-product {
        width: 11px;
        height: 11px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__popup-description {
        padding: 40px 52px 40px 40px;
    }
    &__popup-info {
        display: flex;
        img {
            object-fit: cover;
        }
    }

    &__popup-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        padding-bottom: 12px;
    }
    &__popup-main {
        display: flex;
    }
    &__popup-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    // &__slider {
    //     margin-right: 40px;
    // }
    .carousel__img {
        width: 100%;
        min-height: 435px;
        max-height: 800px;
        object-fit: cover;
    }
    &__add-text {
        padding: 16px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        justify-content: center;
    }

    &__favorite {
        border: 1px solid #000;
        cursor: pointer;
        &:hover {
            background: #000;
            .product__favorite-text {
                color: #fff;
                
            }
            .favorite-icon {
                fill: #fff;
            }
        }
    }
    &__favorite-content {
        display: flex;
        align-items: center;
        min-width: 190px;
        justify-content: center;
        transition: all 0.3s;

    }

    .favorite-icon {
        width: 22px;
        height: 22px;
        transition: all 0.3s;
        fill: #000;
    }

    &__favorite-text {
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        transition: all 0.3s;
        letter-spacing: 0.02em;
        color: #000;
        margin-left: 10px;
        text-transform: uppercase;
    }

    &__botoom-info {
        padding-top: 60px;
    }

    &__bottom-content {
        position: relative;
    }
    &__bottom-item--tiles {
        display: flex;
    }
    &__bottom-item {
        color: #a9a9a9;
        cursor: pointer;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }
    &__bottom-text {
        color: #474747;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
    .payment-text,
    .delivery-text,
    .return-text {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    &__bottom-container {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        padding-top: 15px;
        position: absolute;
        bottom: 0;
    }
    .active-return,
    .active-payment,
    .active-delivery {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s;
        position: relative;
        margin-right: auto;
        top: 0;
    }
    .active-delivery .product__info-delivery {
        color: #000;
        transition: all 0.3s;
        text-decoration: underline;
        text-underline-offset: 3px;
        &:hover {
            color: var(--golden);
        }
    }
    &__bottom-desc {
        font-weight: 300;
        line-height: 24px;
        margin-top: 5px;
    }
    .active-delivery .product__bottom-desc {
        color: #000;
    }

    &__info-delivery {
        // border-bottom: 1px solid #000;
    }

    &__social {
        padding-top: 26px;
    }

    &__social-content {
        display: flex;
    }
    &__social-link {
        margin-right: 40px;
        width: 13px;
        height: 18px;
    }
    &__social-link:last-child {
        margin-right: 0!important;
    }
    &__social-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        padding-right: 17px;
        color: #474747;
    }
    &__info-delivery {
        margin-left: 5px;
        cursor: pointer;
    }
    .gallery__white {
        padding-bottom: 80px;
    }
}
.category__size-product.active {
    pointer-events: none;
    color: #a9a9a9;
}
.active__product-popup {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
}
.breadcrumbs.product__breadcrumbs {
    top: 30px;
}
.gallery__size-chart {
    // border-bottom: 1px solid #000;
    display: inline-block;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.01em;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: underline;
    text-underline-offset: 3px;
    &:hover {
        color: var(--golden);
        // border-bottom: 1px solid var(--golden);
    }
}
.size.active {
    display: block;
    transition: all 0.3s;
}
.phone__popup {
    z-index: 99999;
    position: relative;
    max-width: 900px;
    display: none;
    transition: all 0.3s;
    z-index: 99999;
    position: relative;
    width: 100%;
    padding: 0px;
    margin: 40px 0;
    height: 100%;
    .error-mesage {
        margin-top: 0;
    }
    .popup__newadd-input:last-child {
        margin-bottom: 0 !important;
    }
}
.phone__popup-content {
    background: #fff;
    width: 100%;
    margin-bottom: 20px;
    left: 0;
    position: relative;
    padding: 20px;
    .contact__send {
        margin-left: auto;
        margin-right: auto;
        max-width: 156px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bag-popup__content:last-child {
        padding: 0;
    }
    .bag-popup__info {
        width: 100%;
    }
    .bag-popup__price {
        padding: 0;
    }
    .bag-popup__info-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .bag-popup__img {
        height: 110px;
    }
    .bag-popup__content {
        margin-bottom: 40px;
    }
    .bag-popup__article {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
    .bag-popup__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
    }
    .bag-popup__price {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
    }
    .phone__login-name,
    .phone__popup-name {
        margin-bottom: 26px;
    }
}
.phone__popup.active {
    display: block;
}
.phone__popup-title {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
    color: #131313;
    margin-bottom: 25px;
}
.phone__popup-inputs {
    width: 100%;
    .phone__popup-phone {
        width: 100%;
    }
    .phone__popup-name {
        width: 100%;
        margin-right: 10px;
    }
    .registration__name-input {
        margin-bottom: 0 !important;
    }
}
.size {
    display: none;
    position: relative;
    transition: all 0.3s;
    z-index: 99999;
    margin: 0 10px;
    padding: 30px;
    // padding-bottom: 50px;
    background: #ffffff;
    overflow: auto;
    scrollbar-color:#d5ba6c #f2f1ec ;
    scrollbar-width: thin;
    img {
        width: 100%;
    }
    // &__tabel-img {
    //     // padding-bottom: 10px;
    // }
    ::-webkit-scrollbar {
        width: 1px !important;
        height: 1px !important;
    }
    ::-webkit-scrollbar-track {
        background: #f2f1ec;
        width: 1px !important;
        height: 3px !important;
    }
    ::-webkit-scrollbar-thumb {
        background: #d5ba6c;
        width: 1px !important;
        height: 1px !important;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #d5ba6c;
    }
    .close-product {
        width: 11px;
        height: 11px;
    }
    .product__popup-close {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}
.active {
    &__product-title {
        color: #474747;
    }
    &__product-info {
        opacity: 1;
        visibility: visible;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
        width: 100%;
        padding-top: 15px;
    }
    &__product-text {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        opacity: 1;
        visibility: visible;
        white-space: nowrap;
    }
}

.product__favorite {
    fill: #000;
    transition: all 0.3s;
}
.product__favorite.active-fav {
    .favorite-icon {
        fill: #d5ba6c;
        transition: all 0.3s;
    }
}
.close-product {
    fill: #fff;
}

.product__favorite {
    display: flex;
    justify-content: center;
    padding: 16px 8px;
    height: 53px;
    background: #fff;
    width: calc(50% - 95px);
}

@media (max-width: 1250px) {
    .phone__popup-content {
        .contact__send {
            margin-top: 50px;
        }
    }
    .product {
        &__botoom-info {
            padding-top: 40px;
        }

        &__popup.active__product-popup, &__buy-content {
            display: block;
        }
        &__price-buy {
            display: flex;
            margin-right: 0;
            justify-content: center;
            margin-bottom: 20px;
            margin-top: 20px;
            max-height: 44px;
            font-size: 13px;
            line-height: 16px;
        }
        &__price-add {
            display: flex;
            justify-content: center;
            max-height: 44px;
            margin: 20px 0;
            width: 100%;
        }
        &__buy-text,
        &__add-text, &__favorite-text {
            font-size: 13px;
            line-height: 16px;
        }
        &__favorite {
            max-height: 44px;
            width: 100%;
        }
        // &__slider {
        //     margin-right: 20px;
        // }
    }

}

@media (max-width: 968px) {
    .phone__popup {
        // padding: 20px 10px;
        // margin: 0 10px;
    }
    .phone__popup-content {
        .bag-popup__info-top {
            flex-direction: column;
            align-items: flex-start;
            font-size: 16px;
            line-height: 19px;
        }
        .bag-popup__title {
            font-size: 16px;
            line-height: 19px;
        }
        .bag-popup__price {
            font-size: 16px;
            line-height: 19px;
        }
        .bag-popup__article {
            margin-bottom: 8px;
        }
        .bag-popup__img {
            height: 104px;
        }
    }
    .phone__popup-title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 15px;
    }
    .phone__popup-inputs {
        flex-direction: column;
    }
    .phone__popup-name {
        margin-bottom: 26px;
    }
    .product {
        &__slider {
            max-width: 100%;
        }
        &__info {
            max-width: 100%;
            margin-top: 25px;
        }
        &__content {
            margin-bottom: 34px;
            display: block;
            flex-direction: column;
        }
        .slick-list {
            max-width: 100%;
        }
        .carousel__img {
            max-width: auto;
        }
        .minus-btn,
        .plus-btn {
            width: 42px;
            height: 44px;
        }
        .bag-popup__input {
            margin-right: 0px;
        }
        .bag-popup__counter {
            width: 45px;
            height: 44px;
        }
    }
    .product {
        .size {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

@media (max-width: 768px) {


    .product {
        &__favorite {
            height: 44px;
        }
        &__popup-description {
            padding: 20px;
        }
        &__popup-text {
            font-size: 12px;
        }
        &__popup-title {
            font-size: 24px;
        }
        &__popup-info {
            flex-direction: column-reverse;
        }
        &__popup.active__product-popup {
            img {
                object-fit: cover;
                max-height: 300px;
                width: 100%;
            }
        }
        &__bottom-item--tiles, 
        &__social-content {
            display: flex;
            justify-content: center;
        }
        &__bottom-desc {
            display: block;
            text-align: center;
        }
    }

    .size__tabel {
        padding: 20px 0;
    }
}
