.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    transform: rotate(45deg);
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    &__container {
        position: fixed;
        z-index: 999999;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1e1e1e;
        left: 0;
        top: 0;
    }
}
.loader::before {
    content: "";
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: -24px;
    animation: animloader 4s ease infinite;
}
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: rgba(213, 186, 108, 0.85);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    animation: animloader2 2s ease infinite;
}

@keyframes animloader {
    0% {
        box-shadow: 0 24px #fff, 24px 24px #fff, 24px 48px #fff, 0px 48px #fff;
    }
    12% {
        box-shadow: 0 24px #d5ba6c, 24px 24px #fff, 24px 48px #fff, 0px 48px #fff;
    }
    25% {
        box-shadow: 0 24px #d5ba6c, 24px 24px #d5ba6c, 24px 48px #fff, 0px 48px #fff;
    }
    37% {
        box-shadow: 0 24px #d5ba6c, 24px 24px #d5ba6c, 24px 48px #d5ba6c, 0px 48px #fff;
    }
    50% {
        box-shadow: 0 24px #d5ba6c, 24px 24px #d5ba6c, 24px 48px #d5ba6c, 0px 48px #d5ba6c;
    }
    62% {
        box-shadow: 0 24px #fff, 24px 24px #d5ba6c, 24px 48px #d5ba6c, 0px 48px #d5ba6c;
    }
    75% {
        box-shadow: 0 24px #fff, 24px 24px #fff, 24px 48px #d5ba6c, 0px 48px #d5ba6c;
    }
    87% {
        box-shadow: 0 24px #fff, 24px 24px #fff, 24px 48px #fff, 0px 48px #d5ba6c;
    }
    100% {
        box-shadow: 0 24px #fff, 24px 24px #fff, 24px 48px #fff, 0px 48px #fff;
    }
}

@keyframes animloader2 {
    0% {
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }
    25% {
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }
    50% {
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }
    75% {
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }
    100% {
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}
