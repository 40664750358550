@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latoblack/latoblack.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latobold/latobold.ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latosemibold/latosemibold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latomedium/latomedium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latoregular/latoregular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "lato";
    src: url("../../assets/fonts/latolight/latolight.ttf");
    font-weight: 300;
    font-style: normal;
}
