.video {
    position: relative;
    height: 100%;
    transition: all 0.3s;
    cursor: pointer;
}
.video .hr_overflow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: all 0.3s;
    background: rgba(0,0,0, 0.34);
    z-index: 1;
}
.video:hover .hr_overflow {
    background: transparent;
    transition: all 0.3s;
    z-index: 0;
}
/*.video:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: 0;*/
/*    right: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    background: rgba(0,0,0, 0.34);*/
/*    transition: all 0.3s;*/
/*}*/
.video:hover:after {
    display: none;
    transition: all 0.3s;
}
.video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
}
.video .content h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: var(--white);
    text-align: left;
}
.video .content .btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s;
    background: var(--white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    width: 60px;
    height: 60px;
    cursor: pointer;
}
.video:hover .content .btn {
    transition: all 0.3s;
    animation: 0.8s infinite animationBlurBtn;
}
.video .content .btn_blur {
    content: '';
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.video .content .btn svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.video:hover .content .btn svg{
    fill: var(--golden);
    transition: all 0.3s;
}

@keyframes animationBlurBtn {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }

}