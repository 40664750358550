.placing {
    &__container {
        max-width: 860px;
        margin: 0 auto;
        padding: 0 10px;
    }
    &__content {
        padding: 80px 0;
    }
    &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    &__info {
        background: #f3f3f3;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        margin-bottom: 30px;
    }
    &__info-left,
    &__info-right {
        width: 50%;
    }
    &__info-name,
    &__info-number,
    &__info-address {
        margin-bottom: 20px;
    }
    &__info-name--label,
    &__info-number--label,
    &__info-mail--label,
    &__info-address--label,
    &__info-detail--label {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
        color: #000000;
    }
    &__info-name--text,
    &__info-number--text,
    &__info-mail--text,
    &__info-address--text,
    &__info-detail--text {
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: 0.05em;
        color: #494949;
    }
    &__order {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #a9a9a9;
        max-height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            margin-bottom: 10px;
        }
    }
    &__order-item {
        display: flex;
        align-items: center;
    }
    &__order-item:not(:last-child) {
        margin-bottom: 36px;
    }
    &__order-image {
        width: 100px;
        height: 100px;
        background: #d5ba6c;
        object-fit: cover;
    }
    &__order-content {
        margin-left: 36px;
    }
    &__order-content--title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
        color: #000000;
        margin-bottom: 12px;
    }
    &__order-content--article {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 12px;
    }
    &__order-content--size {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #474747;
        span {
            color: #000;
            margin-left: 5px;
        }
    }
    &__mobile-price {
        display: none;
    }
    &__order-price {
        display: block;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
        color: #000000;
        margin-left: auto;
    }
    &__total {
        padding: 40px 20px;
    }

    &__total-sub,
    &__total-delivery,
    &__total-discount,
    &__total-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: #131313;
    }
    &__total-sub,
    &__total-delivery,
    &__total-discount {
        margin-bottom: 20px;
    }
    &__total-total {
        padding-top: 10px;
    }
    &__btn {
        width: 257px;
        height: 52px;
        background: #000000;
        color: #fff;
        border: 1px solid #000;
        text-transform: uppercase;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            background: #fff;
            color: #000;
            transition: all 0.3s;
        }
    }
}

@media (max-width: 768px) {
    .placing {
        &__content {
            padding: 30px 0 50px;
        }
        &__title {
            text-align: center;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 20px;
        }
        &__info {
            padding: 30px 10px;
            flex-direction: column;
        }
        &__info-left,
        &__info-right {
            width: 100%;
        }
        &__info-name,
        &__info-number,
        &__info-mail,
        &__info-address,
        &__info-detail {
            margin-bottom: 20px;
        }
        &__order-item:not(:last-child) {
            margin-bottom: 30px;
        }
        &__mobile-price {
            display: flex;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #131313;
        }
        &__order{
            height: 360px;
        }
        &__order-price {
            display: none;
        }
        &__order-content--title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
        }
        &__order-content--article {
            margin-bottom: 5px;
        }
        &__order-content--size {
            margin-bottom: 10px;
            span {
                margin-left: 10px;
            }
        }
        &__order-content {
            margin-left: 20px;
        }
        &__order-image {
            width: 70px;
            height: 90px;
        }
        &__total-sub,
        &__total-delivery,
        &__total-discount,
        &__total-total {
            font-size: 14px;
            line-height: 28px;
        }
        &__btn{
            width: 100%;
        }
    }
}
