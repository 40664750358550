.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
    }
    &__back {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000000;
        margin: 0 auto;
        width: 240px;
        height: 52px;
        border: 1px solid #000000;
        cursor: pointer;
        transition: all 0.3s;
        position: relative;
        top: -80px;
        margin-top: 34px;
        &:hover {
            color: #fff;
            background: #000;
            transition: all 0.3s;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 100px;
        line-height: 120px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        background: linear-gradient(
            90deg,
            #c1af7b 0%,
            #fffcb3 22.56%,
            #f9ee8e 39.75%,
            #ae8d48 57.6%,
            #9a7333 73.61%,
            #c4a756 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position: relative;
        top: -80px;
    }
}

@media (max-width: 968px) {
    .error {
        padding-top: 50px;
        padding-bottom: 20px;
        &__title {
            font-size: 60px;
            line-height: 1.2;
            top: -40px;
        }
        &__back{
            width: 100%;
            top: -40px;
        }
    }
}


@media (max-width: 768px) {
    .error {
        &__title {
            font-size: 48px;
        }
        &__back{
            width: 100%;
            top: 0px;
        }
    }
}