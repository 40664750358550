.copyright {
    border-top: 2px solid #d5ba6c;
    background: #000000;
    &__content {
        color: #fff;
        display: flex;
        padding: 12px 0;
        position: relative;
    }

    &__minicode {
        display: flex;
        img {
            width: 96px;
            height: 21px;
        }
    }

    &__minicode-text {
        padding-right: 10px;
    }
}
