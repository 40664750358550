.category__content.wish-content {
    .active-category {
        transition: all 0.3s;
        color: #ffffff;
        background: #d5ba6c;
    }

    .gallery__slider {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1200px) {
        .gallery__slider {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 768px) {
        .gallery__slider {
            grid-template-columns: repeat(2, 1fr);
        }
    }


}