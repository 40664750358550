.main {
    // background-image: url(../../../assets/images/pictures/main-bg.jpg);
    background-repeat: no-repeat;
    background-position: center -200px;

    &__content {
        max-width: 545px;
        padding-left: 70px;
        padding-top: 100px;
        padding-bottom: 160px;
        color: #ffffff;
        text-transform: uppercase;
    }

    &__pretitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.2em;
        border-bottom: 1px solid #fff;
        padding-bottom: 4px;
        display: inline-block;
    }

    &__title {
        padding-top: 10px;
        font-weight: 500;
        font-size: 70px;
        line-height: 74px;
        letter-spacing: 0.04em;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 22px;
        line-height: 70px;
        letter-spacing: 0.06em;
    }
    &__title span {
        font-weight: 800;
    }
    &__btn {
        padding: 16px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: inline-block;
        align-items: center;
        letter-spacing: 0.05em;
        border: 1px solid #ffffff;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            border: 1px solid #d5ba6c;
            color: #fff;
            background: #d5ba6c;
        }
    }
}

.gallery {
    &__left-img.mobile-img,
    &__right-img.mobile-img {
        display: none;
    }
    &__banner {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
    }
    &_content {
        display: flex;
        height: 100%;
        justify-content: space-between;
        flex-flow: column;
        padding: 100px 50px;
    }
    &__left {
        margin-right: 36px;
        width: 100%;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s;
        &-btn {
            max-width: max-content
        }
        &:hover {
            .gallery__left-img {
                transform: scale(1.1);
                transition: all 0.3s;
                right: -3%;
            }
            .gallery__left-btn {
                border: 1px solid transparent;
                background: #fff;
                color: #000;
                transition: all 0.3s;
            }
            .gallery__left-content {
                padding-top: 80px;
                @media screen and (max-width: 768px) {
                    padding-top: 40px;
                }
            }
        }
    }

    &__images {
        width: 100%;
    }
    &__image {
        width: 100%;
        position: relative;
        top: 2px;
    }

    &__left-content {
        // height: 470px;
        transition: all 0.3s;
        color: #fff;
        position: relative;
        z-index: 1;
    }
    &__left-img {
        position: absolute;
        bottom: 0;
        right: 0;
        transition: all 0.3s;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &__left-title {
        font-style: 500;
        font-weight: 600;
        font-size: 48px;
        line-height: 1;
        transition: all 0.3s;
    }

    &__left-text {
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.01em;
        padding-bottom: 20px;
        transition: all 0.3s;
    }

    &__left-btn {
        display: inline-block;
        border: 1px solid #fff;
        transition: all 0.3s;
        padding: 16px 24px;
    }

    &__right {
        width: 100%;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        overflow: hidden;
        height: 472px;
        &-btn {
            max-width: max-content;
        }
        &:hover {
            .gallery__right-img {
                transform: scale(1.1);
                transition: all 0.3s;
                right: -3%;
            }
            .gallery__right-btn {
                border: 1px solid transparent;
                background: #fff;
                color: #000;
                transition: all 0.3s;
            }
            .gallery__right-content {
                padding-top: 80px;
                @media screen and (max-width: 768px) {
                    padding-top: 40px;
                }
            }
        }
    }
    &__right-content {
        width: 100%;
        // height: 470px;
        transition: all 0.3s;
        color: #fff;
        position: relative;
        z-index: 1;
    }
    &__right-img {
        position: absolute;
        bottom: 0;
        right: 0;
        transition: all 0.3s;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    &__right-title {
        font-style: 500;
        font-weight: 600;
        font-size: 48px;
        line-height: 1;
        // color: #fff;
        transition: all 0.3s;
    }
    &__right-title span {
        font-size: 40px;
    }

    &__right-text {
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.01em;
        padding-bottom: 20px;
        transition: all 1s;
    }

    &__right-btn {
        display: inline-block;
        border: 1px solid #fff;
        transition: all 1s;
        padding: 16px 24px;
    }
    &__content {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    &__white {
        background: #fff;
    }
    &__grey {
        background: #f3f3f3;
    }
    &__view-title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
    
    }

    &__view-subtitle {
        text-align: center;
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 32px;
        text-transform: uppercase;
        padding-top: 5px;
    }
    &__view-subtitle span {
        font-weight: 700;
        padding-left: 5px;
    }

    &__btn {
        border: 1px solid #000;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 3px;
        transition: all 0.3s;
        display: inline-block;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin-top: 48px;
        &:hover {
            border: 1px solid transparent;
            background: #000;
            color: #fff;
        }
    }
    &__btn-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.insta {
    &__content {
        color: #000;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        padding-top: 80px;
        text-transform: uppercase;
    }
    &__link {
        width: 100%;
        height: 336px;
        background: #24426a;
        color: #fff;
    }

    &__link:nth-child(2) {
        background: #18574a;
    }

    &__link:nth-child(3) {
        background: #cba13e;
    }
    &__link:nth-child(4) {
        background: #2b9fa7;
    }
    &__link:nth-child(5) {
        background: #dab1b1;
    }
    &__link:nth-child(6) {
        background: #971e34;
    }

    &__link:nth-child(7) {
        background: #666689;
    }

    &__link:nth-child(8) {
        background: #73513c;
    }
    &__text {
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        padding-bottom: 34px;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__link {
        position: relative;
        transition: all 0.3s;
        max-width: 100%;
        z-index: 1;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 0%;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s;
            opacity: 0;
            visibility: hidden;
            left: 0;
        }
        &:hover {
            .insta__link-text {
                transition: all 0.3s;
                opacity: 1;
                visibility: visible;
                z-index: 100;
            }
            .insta__number-like {
                transition: all 0.3s;
                opacity: 1;
                visibility: visible;
                z-index: 100;
            }
            .insta__number-comment {
                transition: all 0.3s;
                opacity: 1;
                visibility: visible;
                z-index: 100;
            }
        }
        &:hover::after {
            transition: all 0.3s;
            opacity: 1;
            visibility: visible;
            height: 100%;
        }
    }
    &__text span {
        padding-left: 5px;
        font-weight: 300;
    }
    &__number {
        display: flex;
        justify-content: center;
        gap: 100px;
        padding-top: 70px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        transition: all 0.3s;
        z-index: 10;
    }
    &__link-text {
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 50px;
        font-size: 16px;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        z-index: 100;
        position: absolute;
    }
    &__number-like {
        position: relative;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        z-index: 100;
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/white-heart.svg);
            width: 24px;
            height: 24px;
            right: 30px;
            top: 2px;
        }
    }

    &__number-comment {
        position: relative;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        z-index: 100;
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/bubble-chat.svg);
            width: 24px;
            height: 24px;
            right: 30px;
        }
    }
    &__gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 144px;
    }
}
._2R-kh {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 144px;
}
._wPDyp {
    margin: 0;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0%;
        opacity: 0.5;
        z-index: 2;
        background-color: #000;
        bottom: 0;
        transition: all 0.3s;
    }
    &:hover::after {
        height: 100%;
        transition: all 0.3s;
    }
    &:hover {
        ._3xnQP {
            opacity: 1;
            transition: all 0.3s;
        }
    }
}
._3xnQP {
    transition: all 0.3s;
}

._wPDyp {
    flex: 0 0 calc(100% / 6 - 10px);
    display: block;
    position: relative;
}
._vzTHL {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.25s;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
._3xnQP {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    opacity: 0;
}

._3lhLL {
    text-align: center;
    color: red;
    font-size: 18px;
}

._1I_qj {
    position: relative;
}

._3qwMT {
    position: absolute;
    padding-bottom: 67%;
    margin-bottom: 4rem;
    width: 100%;
    background: #ffdd28;
    opacity: 1;
    transition: opacity 2s ease;
}

._3rVly {
    opacity: 0;
}
@media (max-width: 1260px) {
    .gallery {
        &__right-content,
        &__left-content {
            display: flex;
        }
        &__right-img,
        &__left-img {
            // width: 40%;
        }
        &__right-content,
        &__left-content {
        }
        &__right-img,
        &__left-img {
            // width: 40%;
        }
        &__left-title,
        &__right-title {
            font-size: 30px;
            line-height: 36px;
        }
        &__left-text,
        &__right-text {
            font-size: 18px;
            line-height: 22px;
        }

    }
}
@media (max-width: 1024px) {
    .gallery__view-title {
        max-width: 340px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    body ._2R-kh {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
    }
    .gallery {
        margin-top: 10px;
        margin-bottom: 10px;
        &__top {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        &__banner {
            display: block;
        }
        &__left {
            margin-bottom: 10px;
        }
        &__slider {
            max-width: 900px;
            grid-template-columns: repeat(2, 1fr);
            margin: 0 auto;
            grid-gap: 20px;
        }

        &__right,
        &__left {
            height: 215px;
        }
        &__right-title,
        &__left-title {
            font-size: 30px;
            line-height: 30px;
            // max-width: 130px;
        }
        &__right-title span {
            font-size: 20px;
        }
        &__right-text,
        &__left-text {
            font-size: 11px;
            line-height: 17px;
            // max-width: 116px;
            padding-bottom: 4px;
        }
        &__left-content,
        &__right-content {
            width: 100%;
            // padding-left: 27px;
            max-width: 320px;
            // height: 215px;
            
        }
        &__left,
        &__right {
            &:hover {
                .gallery__left-content {
                    transition: all 0.3s;
                }
                .gallery__right-content {
                    transition: all 0.3s;
                }
            }
        }
        &__right-btn,
        &__left-btn {
            padding: 11px 16px;
        }
        &__btn{
            margin-top: 30px;
        }
        &__btn-text {
            font-size: 11px;
            line-height: 13px;
        }
        &__content {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &_content {
            padding: 40px 20px;
        }
        
        &__view-subtitle {
            line-height: 25px;
            padding-bottom: 20px;
        }

        &__image-title {
            font-size: 10px;
            line-height: 12px;
            padding-bottom: 3px;
        }
        &__image-price {
            font-size: 10px;
            line-height: 12px;
        }
        &__discount {
            font-size: 7px;
            line-height: 8px;
            padding: 3px;
            top: 5px;
            left: 5px;
        }

        &__size-link {
            font-size: 14px;
        }
        &__size-item {
            font-size: 12px;
            height: 30px;
        }
        &__size-items {
            height: 30px;
        }
        &__btn {
            margin-top: 10px;
            padding: 11px 16px;
        }
    }
    .insta__gallery {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .gallery {
        &__image-bag {
            width: 28px;
            height: 28px;
        }

        &__fav {
            top: 6px;
            right: 6px;
            width: 28px;
            height: 28px;
        }
        .icon.fav {
            width: 15px;
            height: 15px;
        }
        .icon.gal-bag {
            width: 15px;
            height: 15px;
        }
        &_content {
            padding: 40px 20px;
            height: 100%;
            display: flex;
            justify-content: space-between;
        }

    }

    .main {
        background-position: center;
        background-size: cover;
        background-image: url("../../../assets/images/pictures/main-bgmobile.jpg");
        &__content {
            padding-left: 0;
            max-width: 276px;
            padding-top: 327px;
            padding-bottom: 56px;
        }
        &__pretitle {
            font-size: 8px;
            line-height: 10px;
            padding-bottom: 0;
        }

        &__title {
            padding-top: 4px;
            font-size: 37px;
            line-height: 39px;
        }

        &__subtitle {
            font-size: 11px;
            line-height: 37px;
        }

        &__btn {
            padding: 14px 21px 14px 20px;
            max-height: 44px;
        }

        &__btn-text {
            font-size: 13px;
            line-height: 16px;
        }
    }
    .insta {
        &__title {
            padding-top: 40px;
        }
        &__link {
            height: 160px;
            overflow: hidden;
        }
        &__text {
            padding-bottom: 30px;
        }
        &__number {
            padding-top: 40px;
            gap: 50px;
        }
        &__link-text {
            font-size: 14px;
            padding-top: 25px;
        }
        &__number-like {
            font-size: 16px;
            &::before {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                right: 30px;
                top: 2px;
            }
        }

        &__number-comment {
            font-size: 16px;
            &::before {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                right: 30px;
            }
        }
    }
}


.banner_top,.category-baner  {
    position: relative;
     &::before {
        background-color: rgba(0, 0, 0, 0.5);  
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        left: 0;
    }      

    @media (max-width: 768px) {
        max-height: 260px;
    }
}

.gallery__left, .gallery__right{
    position: relative;
    &::before {
        background-color: rgba(0, 0, 0, 0.5);  
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        left: 0;
    }  
}

.react-player {
    &-title {
        margin-bottom: 20px;
    }
}