.privacy {
    color: #474747;
    &__article:not(:last-child) {
        margin-bottom: 44px;
    }
    &__article-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        margin-bottom: 30px;
        color: #474747;
    }
    &__article-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #474747;
    }
    &__article-text:not(:last-child) {
        margin-bottom: 20px;
    }
    &__article-question {
        color: #474747;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    &__article {
        p {
            padding: 5px;
        }
    }
}

@media (max-width: 968px) {
    .privacy-page{
        .profile__address-title{
            margin-bottom: 20px;
        }
    }
    .privacy {
        &__article:not(:last-child) {
            margin-bottom: 40px;
        }
        &__article-title {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        &__article-text {
            font-size: 16px;
            line-height: 24px;
        }
        &__article-question {
            font-size: 16px;
            line-height: 19px;
        }
    }
}
