.blog {
    margin-top: 34px;
    margin-bottom: 144px;

    &__post-image {
        height: 360px;
        max-width: 980px;
        object-fit: cover;
        width: 100%;
        &.fullwidth {
            max-width: 100%;
        }
    }

    &__content {
        display: flex;
    }

    &__info {
        max-width: 303px;
        margin-right: 36px;
    }

    &__info-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-bottom: 20px;
    }
    &__info-description {
        font-size: 16px;
        line-height: 1.6;
        color: #474747;
    }

    &__info-items:not(:last-child) {
        padding-bottom: 16px;
    }
    &__description-container {
        // background-image: url(../../../assets/images/pictures/blog-1.jpg);
        background-size: cover;
        max-height: 360px;
    }
    &__posts-description {
        margin-top: 34px;
    }
    &__info-post {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #474747;
        padding-bottom: 5px;
    }

    &__info-date {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #a9a9a9;
    }

    &__info-text {
        max-width: 303px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
    }

    &__tags {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
        padding-top: 48px;
    }

    &__items-tags {
        display: flex;
        flex-wrap: wrap;
    }

    &__item-tag {
        font-weight: 400;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #f3f3f3;
        padding: 5px;
        cursor: pointer;
    }
    &__description-content {
        padding: 0 77px;
    }
    &__posts {
        position: relative;
        width: 100%;
    }
    &__posts-img {
        max-width: 100%;
        object-fit: cover;
        min-height: 205px;
        object-position: center;
    }
    &__posts-background {
        position: absolute;
        z-index: -1;
        max-width: 100%;
        min-height: 205px;
    }

    &__posts-pretitle {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #ffffff;
        border-bottom: 1px solid #fff;
        display: inline-block;
        padding-bottom: 3px;
    }
    .product__social {
        padding-top: 0;
    }
    &__top-description {
        
    }
    &__posts-title {
        font-weight: 500;
        font-size: 50px;
        line-height: 54px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #ffffff;
        padding-top: 5px;
        max-width: 370px;
        padding-bottom: 10px;
    }
    &__posts-title span {
        font-weight: 800;
    }

    &__posts-date {
        font-weight: 400;
        font-size: 16px;
        line-height: 51px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #ffffff;
    }

    &__info-description {
        // margin-bottom: 100px;
    }
    &__description-top {
        display: flex;
        justify-content: space-between;
    }
    &__info-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #000000;
        padding-bottom: 20px;
    }
    &__description-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 39px;
        text-transform: uppercase;
        color: #000000;
        padding-bottom: 6px;
    }
    &__social {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        color: #474747;
    }

    &__description-subtitle {
        display: flex;
        padding-bottom: 24px;
    }

    &__description-by {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        padding-right: 20px;
        color: #000000;
    }

    &__description-author {
        color: #d5ba6c;
        padding-left: 5px;
    }

    &__description-month {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        padding-right: 20px;
        color: #000000;
    }

    &__description-date {
        color: #a9a9a9;
        padding-left: 5px;
    }
    &__bottom {
        padding: 0 77px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    &__bottom-navigation {
        display: flex;
    }
    &__navigation-old {
        font-weight: 700;
        color: #474747;
        cursor: pointer;
        padding-left: 24px;
        margin-right: 40px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 10px;
            background-image: url(../../../assets/images/icons/blog-arrow.svg);
            left: 0;
            top: 6px;
        }
    }
    &__navigation-new {
        font-weight: 700;
        color: #474747;
        cursor: pointer;
        padding-right: 24px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 10px;
            background-image: url(../../../assets/images/icons/blog-arrow.svg);
            transform: rotate(180deg);
            right: 0;
            top: 6px;
        }
    }
    &__description-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
        padding-bottom: 24px;
    }

    &__description-more {
        color: #474747;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        display: inline-flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        position: relative;
        margin-bottom: 80px;
        padding-right: 20px;
        transition: all 0.3s;
        &:hover {
            color: #d5ba6c;
            transition: all 0.3s;
            &::after {
                transition: all 0.3s;
                background-image: url(../../../assets/images/icons/blog-arrow-yellow.svg);
            }
        }
        &::after {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/blog-arrow.svg);
            background-repeat: no-repeat;
            transform: rotate(180deg);
            width: 15px;
            top: -3px;
            right: -10px;
            height: 100%;
          
        }
    }

    &__desc-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #474747;
    }

    &__bottom-links {
        display: flex;
        justify-content: space-between;
        margin-bottom: 124px;
        margin-top: 50px;
    }

    &__older-post {
        padding-left: 24px;
        padding-right: 40px;
        position: relative;
        font-weight: 700;
        font-size: 15px;
        letter-spacing: 0.02em;

        color: #474747;
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/blog-arrow.svg);
            width: 15px;
            height: 10px;
            top: 6px;
            left: 0px;
        }
    }

    &__newer-post {
        position: relative;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #474747;
        &::after {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/blog-arrow.svg);
            transform: rotate(180deg);
            width: 15px;
            height: 10px;
            top: 6px;
            left: 90px;
        }
    }
}

.about {
    .main__btn-text {
        color: #fff;
    }
    .main {
        display: none;
        &__btn {
            padding: 12px 18px;
        }
        &__btn-text {
            font-size: 12px;
            line-height: 14px;
        }
    }
    .blog__posts-description {
        margin-top: 63px;
    }
}

@media (max-width: 1024px) {
    .about {
        .blog__posts-description {
            margin-top: 40px;
        }
        .main {
            background-position: center;
            background-size: cover;
            display: block;
            margin-top: 34px;
            background-image: url(../../../assets/images/pictures/blog-info.jpg);
            &__content {
                padding-left: 0;
                max-width: 276px;
                padding-top: 327px;
                padding-bottom: 56px;
            }
            &__pretitle {
                font-size: 8px;
                line-height: 10px;
                padding-bottom: 0;
            }

            &__title {
                padding-top: 4px;
                font-size: 37px;
                line-height: 39px;
            }

            &__subtitle {
                font-size: 11px;
                line-height: 37px;
            }
            &__btn {
                padding: 9px 13px;
            }

            &__btn-text {
                font-size: 9px;
                line-height: 10px;
            }
        }
    }
    .blog {
        &__content {
            margin-top: 20px;
            flex-wrap: wrap;
        }
        margin-top: 0px;
        margin-bottom: 50px;
        &__info {
            // display: none;
            max-width: 100%;
        }
        &__tags {
            margin-top: 20px;
            padding-top: 0;
        }
        &__posts-title {
            font-weight: 500;
            font-size: 33px;
            line-height: 36px;
        }
        &__posts-date {
            line-height: 14px;
            font-size: 10px;
            font-weight: 400;
        }
        &__posts-pretitle {
            font-size: 7px;
            line-height: 8px;
        }
        &__top-description {
            padding: 36px 0px 36px 60px;
        }
        .product__social {
            display: none;
            margin-bottom: 40px;
        }
        &__description-content {
            padding: 0;
        }
        &__description-more {
            margin-bottom: 60px;
        }
        &__bottom {
            padding: 0;
            flex-direction: column-reverse;
        }
        &__navigation-old {
            margin: 0;
        }
        &__bottom-navigation {
            justify-content: space-between;
            width: 100%;
        }
        &__bottom {
            margin-top: 15px;
        }
        &__description-title {
            font-size: 24px;
            line-height: 36px;
        }
    }
    .blog.about {
        .product__social {
            display: block;
        }
    }
}
