.slick-arrow,
.slick-arrow:focus,
.slick-arrow:hover {
    position: absolute;
    width: 42px;
    height: 42px;
    background: rgba(0, 0, 0, 0.25);
    font-size: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
}

.slick-arrow.slick-next {
    right: 0;
    &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 15px;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        background-image: url(../../assets//images/icons/slider-next.svg);
    }
}
.slick-arrow.slick-prev {
    left: 0;
    &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 15px;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        background-image: url(../../assets//images/icons/slider-prev.svg);
    }
}

.slick-disabled {
    pointer-events: none;
}

.carouse__item div div {
    background-size: cover !important;
}
.carousel__item-image {
    max-height: 773px;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
}
.has-thumb  {
    display: none !important;
}
