.videoPage {
    margin: 34px 0 80px 0;
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.videoPage .item {
    width: 31.333%;
    height: 250px;
}
.pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
}
.pagination  nav {
    margin-top: 0!important;
}
@media (max-width: 1220px) {
    .videoPage .item {
        width: 47.7%;
    }
}
@media (max-width: 820px) {
    .videoPage {
        gap: 0;
        margin: 34px 0 0 0;
    }
    .videoPage .item {
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
        width: 100%;
    }
}
@media (max-width: 578px) {
    .videoPage .item {
        height: 200px;
    }

}