.contact {
    margin-bottom: 142px;

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 34px;
    }

    &__info {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #474747;
    }

    &__info-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
    }

    &__info-text {
        max-width: 566px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-top: 25px;
        padding-bottom: 36px;
    }

    &__info-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px 30px;
    }

    &__hours-title,
    &__location-title,
    &__mail-title,
    &__number-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #474747;
        padding-bottom: 5px;
    }

    &__number-phone {
        display: block;
    }

    &__hours .icon,
    &__mail .icon,
    &__info .icon,
    &__number .icon {
        width: 24px;
        height: 24px;
        margin-bottom: 20px;
    }

    &__location-info br {
        padding-bottom: 5px;
    }

    &__hours-items {
        display: flex;
    }

    &__hours-day {
        padding-right: 5px;
    }

    &__login {
        max-width: 565px;
        width: 100%;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 525px;
            width: 1px;
            background: #f3f3f3;
            left: -95px;
        }
        input {
            margin-bottom: 10px;
        }
    }

    &__login-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        padding-bottom: 25px;
    }

    &__login-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-bottom: 60px;
    }

    &__login-message,
    &__login-mail,
    &__login-name {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        padding-bottom: 10px;
    }

    &__name-input:focus {
        border: 1px solid #474747;
        border-radius: 0;
    }

    &__message-input {
        max-height: 100px;
        padding: 25px 20px;
        min-height: 120px;
        width: 100%;
        transition: all 0.3s;
        border: 1px solid #a9a9a9;
        &::placeholder {
            color: #a9a9a9;
        }
        resize: none;
        &:focus-visible {
            outline: none;
            border: 1px solid #474747;
            border-radius: 0;
        }
    }
    &__number-phones,
    &__mail-text,
    &__location-info,
    &__hours-work {
        font-weight: 400;
    }

    &__send {
        margin-top: 48px;
        display: block;
        background: #000000;
        color: #fff;
        border: 1px solid #000;
        padding: 16.5px 24px;
        max-height: 52px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        white-space: nowrap;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            background: #fff;
            color: #000;
            border: 1px solid #000;
            transition: all 0.3s;
        }
    }
}
.message {
    width: 626px;
    position: relative;
    margin: 0 10px;
    background: #fff;
    &__bg {
        background-image: url(../../../assets/images/pictures/message-bg.jpg);
        background-size: cover;
        height: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__brand {
        height: 120px;
        max-width: 400px;
        width: 100%;
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 2px;
            background: linear-gradient(
                90deg,
                #c1af7b 0%,
                #fffcb3 22.56%,
                #f9ee8e 39.75%,
                #ae8d48 57.6%,
                #9a7333 73.61%,
                #c4a756 100%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }
    }
    &__info {
        padding: 40px 20px 20px 20px;
        text-align: center;
    }
    &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        margin-bottom: 40px;
    }
    &__btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
        width: 156px;
        height: 52px;
        background: #000000;
        margin: 0 auto;
        transition: all 0.3s;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            border: 1px solid #000;
            color: #000;
            background: #fff;
        }
    }
    .product__popup-close {
        top: 1px;
        z-index: 2;
    }
}
@media (max-width: 1200px) {
    .contact {
        margin-bottom: 35px;
        &__content {
            display: block;
        }
        &__info-block {
            margin-bottom: 30px;
            max-width: 340px;
            grid-gap: 30px;
        }
        &__info-title {
            font-size: 24px;
            line-height: 36px;
        }
        &__info-text {
            padding-top: 20px;
        }
        &__number-title,
        &__mail-title,
        &__location-title,
        &__hours-title {
            font-size: 13px;
            line-height: 20px;
            padding-bottom: 3px;
        }
        &__hours .icon,
        &__mail .icon,
        &__info .icon,
        &__number .icon {
            width: 14px;
            height: 14px;
            margin-bottom: 12px;
        }
        &__number-phones,
        &__mail-text,
        &__location-info,
        &__hours-work {
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.01em;
        }
        &__login-title {
            font-size: 24px;
            line-height: 36px;
            color: #131313;
            padding-bottom: 20px;
        }
        &__login-text {
            padding-bottom: 36px;
        }
        &__send {
            margin: 0 auto;
        }
        .contact__message-input {
            width: 100%;
            
        }
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

.contact__login-inputs {
    .error-mesage {
        position: absolute;
        bottom: -20px;
    }
}

@media (max-width: 1024px) {
    .contact {
        .map {
            margin-top: 34px;
        }
        &__content {
            margin-top: 67px;
        }
    }
}
@media (max-width: 968px) {
    .contact__login {
        max-width: 100%;
    }
    .message {
        &__bg {
            height: 220px;
        }
        &__brand {
            height: 80px;
            max-width: 200px;
        }
        &__info {
            padding: 25px 18px 20px 18px;
        }
        &__title {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 25px;
        }
        &__logo {
            max-width: 55px;
        }
    }
}
@media (max-width: 768px) {
    .contact__send {
        max-width: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
}
