.close {
    position: absolute;
    right: 20px;
    top: 10px;
    fill: var(--gray);
    width: 16px;
    height: 16px;
    transition: all 0.3s;
}
.close:hover {
    fill: var(--golden);
    transition: all 0.3;
}