.registration {
    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__sign-in {
        width: 100%;
        margin-right: 94px;
    }

    &__sign-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        padding-bottom: 25px;
    }

    &__content-sign{
        position: relative;
        width: 100%;
        max-width: 565px;
        margin-right: 20px;
    }
    &__sign-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        padding-bottom: 40px;
    }
    &__error {
        color: var(--red);
    }
    &__remember {
        display: inline-flex;
        margin-bottom: 50px;
        cursor: pointer;
    }
    &__checkbox-text{
        cursor: pointer;
    }
    &__remember.active {
        .registration__checkbox {
            &::after {
                content: "";
                opacity: 1;
                visibility: visible;
                transition: all 0.3s;
            }
        }
    }

    &__checkbox {
        border: 1px solid #e1ded5;
        background: transparent;
        width: 14px;
        height: 14px;
        margin-top: 2px;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/login-check.svg);
            width: 10px;
            height: 8px;
            top: 1px;
            left: 1px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
        }
    }

    &__checkbox-text {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #474747;
        padding-left: 12px;
    }

    &__sign-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__sign-btn {
        background: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
        border: 1px solid #000;
        transition: all 0.3s;
        padding: 16.5px 24px;
        max-height: 52px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: #fff;
            color: #000;
            transition: all 0.3s;
        }
    }

    &__sign-forgot {
        // position: absolute;
        // right: 0;
        bottom: 220px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #000000;
        cursor: pointer;
        transition: all 0.3s;
        text-transform: uppercase;
        transition: all 0.3s;
        &:hover {
            color: #d5ba6c;
        }
    }

    &__sing-up {
        width: 100%;
        max-width: 565px;
        margin-left: 20px;
        .input-title {
            margin-bottom: 10px;
        }
    }
    &__pass-bottom {
        display: flex;
        justify-content: space-between;
    }
    &__line {
        width: 1px;
        height: 525px;
        background: #f3f3f3;
    }
    &__register {
        background: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
        border: 1px solid #000;
        transition: all 0.3s;
        padding: 16px;
        max-height: 52px;
        white-space: nowrap;
        // margin-bottom: 143px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        &:hover {
            background: #fff;
            color: #000;
            transition: all 0.3s;
        }
    }
    &__pass {
        width: 100%;
        margin-right: 10px;
    }
    &__confirm {
        width: 100%;
    }
    .input-title {
        font-weight: 400;
        margin-bottom: 10px;
        font-size: 17px;
    }
}

.reset {
    position: relative;
    background: #fff;
    min-width: 630px;
    width: 100%;
    padding: 30px 60px;
    margin: 0 10px;
    &__title {
        text-align: center;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 30px;
        line-height: 1.25;
        text-transform: uppercase;
        color: #131313;
    }
    &__password {
        display: flex;
        width: 100%;
    }
    &__password-input {
        width: 100%;
    }
    &__confirm-input {
        width: 100%;
        margin-left: 10px;
    }
    .contact__send {
        margin: 0 auto;
        margin-top: 25px;
        width: 156px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.close-product {
    width: 11px;
    height: 11px;
}
.product__popup-close {
    display: flex;
    justify-content: center;
    z-index: 2;
    align-items: center;
}
@media (max-width: 1024px) {
    .registration {
        &__sign-forgot{
            bottom: 15px;
        }
        &__content {
            display: block;
            margin-top: 34px;
        }
        &__line {
            display: none;
        }
        &__sign-in {
            margin-right: 0;
        }
        &__sing-up {
            margin-left: 0;
            margin-top: 40px;
        }
        &__register {
            margin-bottom: 35px;
            margin-top: 50px;
        }
        &__pass-bottom {
            display: block;
        }
        &__sign-forgot {
            font-size: 11px;
            line-height: 26px;
        }
        &__sign-title {
            font-size: 24px;
            line-height: 36px;
            padding-bottom: 5px;
        }
    }
}
@media (max-width: 968px) {
    .reset {
        min-width: auto;
        padding: 30px;
        &__title {
            margin-bottom: 36px;
            font-size: 24px;
            line-height: 36px;
        }
        &__password {
            flex-direction: column;
        }
        &__confirm-input {
            margin-left: 0px;
        }
        .contact__send {
            margin-top: 25px;
            width: 100%;
        }
    }
}
