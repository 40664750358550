.main {
    height: 560px;
    position: relative;
}
.main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.main .content {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 530px;
}
.main .content h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    color: var(--white);
    margin-bottom: 30px;
    text-transform: uppercase;
}
.main .content p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: var(--white);
    margin-bottom: 30px;
    text-transform: uppercase;
}
.main .content a {
    width: max-content;
    height: 52px;
    border: 1px solid var(--white);
    padding: 16.5px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.3s;
}
.main .content a:hover {
    transition: all 0.3s;
    background: var(--golden);
    color: var(--white);
    border: 1px solid var(--golden);
}

.winter_collection {
    background: var(--grayBg);
    text-align: center;
    padding: 40px 0;

}
.winter_collection h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 4px;
}
.winter_collection p {
    margin-bottom: 40px;
}
.winter_collection h3,
.winter_collection p {
    text-transform: uppercase;
}
.winter_collection .row_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.winter_collection .row_flex .item {
    width: 31.3%;
    height: 250px;
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
}
.winter_collection .btn {
    cursor: pointer;
    margin: 48px auto 0 auto;
    width: 215px;
    height: 52px;
    background: var(--black);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    white-space: nowrap;
}
.winter_collection .btn:hover {
    transition: all 0.3s;
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
}
@media (max-width: 1200px) {
    .winter_collection .row_flex .item {
        width: 48%;
    }
}
@media (max-width: 992px) {
    .winter_collection .row_flex .item {
        width: 100%;
    }
    .main .content h2 {
        font-size: 42px;
    }

}
@media (max-width: 578px) {
    .main .content {
        width: calc(100% - 11px);
    }
    .main .content h2 {
        font-size: 32px;
    }
    .main .content p {
        font-size: 18px;
    }
    .main .content a {
        width: 180px;
        padding: 16px;
    }
    .winter_collection .row_flex .item {
        height: 200px;
    }
}