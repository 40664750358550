.contact {
    display: flex;
    position: relative;
}
.contact .contact_form {
    border: 1px solid #a9a9a9;
    width: 100%;
    position: absolute;
    padding-top: 30px;
}
.contact .cart__registration {
    margin-top: 310px;
}
.contact .contact_form,
.contact form {
    border-bottom: 0;
}
.signUp {
    border: 1px solid #a9a9a9;
    border-bottom: 0;
    padding: 30px;
}
.signUp form,
.signUp button {
    margin: 0;
}
.signUp_title {
    padding-left: 0!important;
    padding-right: 0!important;
}
@media(max-width: 1200px) {
    .signUp,
    .contact .contact_form {
        border: 0;
    }
    .signUp {
        padding: 0;
    }
    .signUp form {
        max-width: 100%;
    }
}