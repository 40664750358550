.profile {
    input[type="number"] {
        margin-bottom: 25px;
        padding: 0px 20px 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 52px;
        border: 1px solid #a9a9a9;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #474747;
        transition: all 0.3s;
    }
    input[type="number"]:focus {
        border: 1px solid #474747;
        border-radius: 0;
        outline: none;
        transition: all 0.3s;
    }
    input[type="number"]::placeholder {
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #a9a9a9;
    }
    &__content {
        margin-top: 34px;
    }
    &__top-btn {
        border: 1px solid #000;
        margin-right: 20px;
        cursor: pointer;
    }
    &__top-btns {
        display: flex;
        margin-bottom: 60px;
    }
    &__active-btn {
        color: #fff !important;
        background: #000000 !important;
        transition: all 0.3s;
        border: 1px solid #000;
        cursor: default;
    }
    &__personaldata-link {
        color: #000;
        background: #fff;
        transition: all 0.3s;
        max-height: 52px;
        border: 1px solid #000;
        margin-right: 26px;
    }

    .profile__order-oneclick {
        color: rgb(213, 186, 108);
        padding: 0 5px;
    }

    .active-profile {
        display: block;
    }
    .order-empty {
        display: none;
    }
    .order-empty.active-profile {
        display: flex;
    }

    &__data,
    &__address,
    &__order {
        display: none;
    }

    &__personaldata-text {
        padding: 16.5px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    .logout {
        &__btns {
            display: flex;
            justify-content: center;
        }
        &__btn-logout {
            height: 52px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            color: #fff;
            width: 156px;
            text-transform: uppercase;
            margin-left: 20px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            border: 1px solid transparent;
            transition: all 0.3s;
            margin-right: 20px;
            &:hover {
                border: 1px solid #000;
                background: #fff;
                color: #000;
                transition: all 0.3s;
            }
        }
        &__btn-cancel {
            cursor: pointer;
            color: #fff;
            background: #d5ba6c;
            border: 1px solid transparent;
            height: 52px;
            width: 156px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            transition: all 0.3s;
            white-space: nowrap;
            padding: 0 24px;
            &:hover {
                transition: all 0.3s;
                color: #d5ba6c;
                background: #fff;
                border: 1px solid #d5ba6c;
            }
        }
    }
    &__bottom-btns {
        display: flex;
        align-items: center;
    }
    &__logout {
        height: 52px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        color: #fff;
        width: 190px;
        text-transform: uppercase;
        margin-left: 20px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        border: 1px solid transparent;
        transition: all 0.3s;
        &:hover {
            border: 1px solid #000;
            background: #fff;
            color: #000;
            transition: all 0.3s;
        }
    }
    &__myaddress-link {
        color: #000;
        background: #fff;
        transition: all 0.3s;
        max-height: 52px;
        border: 1px solid #000;
        margin-right: 26px;
        &:hover {
            transition: all 0.3s;
            color: #fff;
            background: #000000;
        }
    }

    &__myaddress-text {
        padding: 16.5px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    &__myorder-link {
        color: #000;
        background: #fff;
        transition: all 0.3s;
        max-height: 52px;
        border: 1px solid #000;
        &:hover {
            transition: all 0.3s;
            color: #fff;
            background: #000000;
        }
    }

    &__myorder-text {
        padding: 16.5px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    &__product-cantity-mobile {
        display: none;
    }
    .bag-popup__details {
        margin-top: 10px;
    }
    &__product-size {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #474747;
    }
    &__product-size span {
        color: #000000;
        margin-left: 10px;
    }
    &__data {
        margin-bottom: 144px;
    }
    &__password {
        position: relative;
    }
    &__password-eye {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 40px;
        top: -60px;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #000;
        }
    }
    &__password-eye.active {
    }
    &__data-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        margin-bottom: 44px;
    }
    &__current-change,
    &__new-change {
        display: flex;
    }
    &__mailadd-title,
    &__userdata-title,
    &__password-title,
    &__contact-title,
    &__card-title,
    &__address-title,
    &__myorder-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        padding-bottom: 22px;
    }
    &__curr-add {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 26px;
    }
    &__curr-add--text {
        font-weight: 400;
        letter-spacing: 0.01em;
        padding-left: 5px;
    }

    &__input-newmail {
        margin-right: 36px;
        margin-bottom: 20px;
        width: 100%;
    }
    &__input-mail {
        display: flex;
    }

    &__confmail-title,
    &__newmail-title,
    &__username-title,
    &__lastname-title,
    &__password-conf,
    &__newpass-title,
    &__confpass-new,
    &__mobile-title {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    &__newmail-input {
        margin-top: 10px;
        width: 100%;
    }

    &__input-confmail {
        width: 100%;
    }

    &__confmail-title {
        margin-bottom: 10px;
    }

    &__input-userdata {
        display: flex;
    }

    &__input-username {
        margin-right: 36px;
        width: 100%;
    }
    &__input-lastname {
        width: 100%;
    }
    &__lastname-input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #a9a9a9;
        height: 52px;
        padding: 0px 20px 0px 20px;
        color: #474747;
        transition: all 0.3s;
    }
    &__lastname-input:focus {
        border: 1px solid #474747;
        border-radius: 0;
        outline: none;
        transition: all 0.3s;
    }
    &__lastname-input::placeholder {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #a9a9a9;
    }
    &__password-input {
        max-width: 632px;
    }
    &__show-password {
        max-width: 50%;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 25px;
            height: 2px;
            background: #000;
            transform: rotate(-45deg);
            right: 38px;
            top: -51px;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }
    }
    &__show-password.active {
        &::before {
            transition: all 0.3s ease-in-out;
            width: 0px;
            top: -58px;
            right: 40px;
        }
    }
    &__newpass-inputs {
        display: flex;
    }

    &__newpass-new {
        margin-right: 36px;
        width: 100%;
    }

    &__confpass-new {
        width: 100%;
    }

    &__confpass-input {
        margin-top: 10px;
    }

    &__card-subtitle,
    &__contact-subtitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 26px;
    }
    &__card-subtitle--text,
    &__contact-subtitle--text {
        font-weight: 400;
        letter-spacing: 0.01em;
    }
    &__userdata-title,
    &__contact-title,
    &__password-title {
        padding-top: 15px;
    }

    &__myorder-title,
    &__address-title,
    &__card-title {
        margin-top: 48px;
    }

    &__card-example {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 48px;
        margin-top: 10px;
    }
    &__deliveryadd-input {
        width: 100%;
        border: 1px solid #a9a9a9;
        height: 86px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        color: #000000;
    }
    &__update-text {
        color: #fff;
        background: #d5ba6c;
        border: 1px solid #fff;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transition: all 0.3s;
        white-space: nowrap;
        padding: 0 24px;
        &:hover {
            transition: all 0.3s;
            color: #d5ba6c;
            background: #fff;
            border: 1px solid #d5ba6c;
        }
    }

    &__address {
        margin-bottom: 180px;
    }
    &__add_address {
        padding-bottom: 10px;
    }

    &__address-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #131313;
        padding-bottom: 44px;
    }

    &__addnew-address--text {
        background: #000000;
        color: #fff;
        border: 1px solid #000;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 16.5px 24px;
        max-height: 52px;
        white-space: nowrap;
        max-width: 200px;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            background: #fff;
            color: #000;
        }
    }
    &__delivery-text {
        margin-bottom: 48px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    &__current-address {
        display: block;
    }
    &__new-address--content,
    &__current-address--content {
        margin-top: 26px;
        padding: 30px;
        border: 1px solid #a9a9a9;
    }
    &__new-address--content {
        margin-top: 44px;
    }
    &__current-top {
        display: flex;
        justify-content: space-between;
    }

    &__current-title,
    &__new-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        padding-bottom: 20px;
    }

    &__current-control {
        display: flex;
    }

    &__current-default {
        font-weight: 400;
        color: var(--black);
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/default-check-black.svg);
            background-repeat: no-repeat;
            width: 18px;
            height: 19px;
            left: 0;
            top: 4px;
        }
    }

    &__current-delete {
        margin-left: 30px;
        height: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }

    &__current-edit {
        margin-left: 30px;
        height: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }
    &__data-address,
    &__data-phone,
    &__data-name {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
    }

    &__data-name {
        text-transform: capitalize;
    }
    &__data-phone,
    &__data-name {
        margin-bottom: 10px;
    }
    &__new-address {
        display: block;
    }

    &__new-top {
        display: flex;
        justify-content: space-between;
    }

    &__new-control {
        display: flex;
    }

    &__new-default {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        height: 20px;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }

    &__new-delete {
        height: 20px;
        margin-left: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }

    &__new-edit {
        height: 20px;
        margin-left: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }
    &__add-address {
        margin-top: 44px;
    }

    &__add-text {
        background: #d5ba6c;
        color: #fff;
        border: 1px solid #d5ba6c;
        max-height: 52px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding: 16.5px 24px;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover {
            background: #fff;
            color: #d5ba6c;
        }
    }

    &__myorder {
        padding-bottom: 22px;
    }

    &__myorder-title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        padding-bottom: 48px;
    }

    &__completed-text {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        padding-bottom: 26px;
    }

    &__order-content {
        padding: 30px;
        border: 1px solid #a9a9a9;
        margin-bottom: 26px;
    }

    &__order-top {
        display: flex;
        justify-content: space-between;
    }

    &__order-number {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        padding-bottom: 20px;
    }

    &__order-control {
        display: flex;
        margin-top: 5px;
    }
    &__product-cantity-mobil {
        display: none;
    }
    &__control-completed {
        position: relative;
        padding-left: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: var(--black);
        &::before {
            content: "";
            position: absolute;
            background-image: url(../../../assets/images/icons/default-check-black.svg);
            background-repeat: no-repeat;
            width: 18px;
            height: 19px;
            left: 0;
            top: 4px;
        }
    }
    &__add-address {
        cursor: pointer;
        display: inline-block;
    }
    &__control-report {
        margin-left: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #d5ba6c;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            transition: all 0.3s;
            color: #000;
        }
    }

    &__control-arrow {
        margin-left: 30px;
        cursor: pointer;
        margin-top: 5px;
    }
    .icon.control-arrow {
        transform: rotate(0deg);
        width: 16px;
        height: 16px;
        transition: all 0.3s;
    }
    .icon.control-arrow.active-arrow {
        transform: rotate(180deg);
        transition: all 0.3s;
    }

    &__order-delivery,
    &__order-payment,
    &__order-data {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .profile-phone {
        .error-mesage {
            margin-top: 0px;
        }
    }
    .profile-card {
        .error-mesage {
            margin-top: -10px;
        }
    }
    &__order-cantity {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    &__order-cantity.active {
        margin-bottom: 20px;
    }
    .profile__order-sum {
        margin-top: 0px;
    }

    .profile__order-product:last-child {
        margin-bottom: 10px;
    }
    &__order-product {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
    }
    &__order-product:last-child {
        margin-bottom: 20px;
    }

    &__product-name {
        margin-left: 36px;
    }

    &__name-title {
        margin-top: 25px;
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        gap: 10px;
        .gallery__images-top {
            position: relative;
        }
        .gallery__discount {
            margin: 0;
        }
    }

    &__name-code {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    &__product-cantity {
        margin-left: auto;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        text-align: right;
        letter-spacing: 0.05em;
        margin-top: 20px;
    }

    &__order-bottom {
        margin-left: 20px;
    }
    &__order-discount {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
    }
    &__order-bg {
        background: #f3f3f3;
        margin-left: -20px;
    }
    &__order-bottom {
        margin-top: 8px;
    }
    &__order-total {
        display: flex;
        justify-content: space-between;
    }

    &__total-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 52px;
        letter-spacing: 0.05em;
        color: #131313;
    }
    &__report-mobile {
        display: none;
    }
    &__total-money {
        font-weight: 600;
        font-size: 20px;
        line-height: 52px;
        letter-spacing: 0.05em;
        color: #131313;
    }
    &__order-sum {
        margin-top: 10px;
        .profile__total-money {
            font-weight: 700;
        }
    }
    &__product-right {
        margin-left: auto;
        display: flex;
    }
    .popup__address {
        .error-mesage {
            margin-top: -30px;
        }
    }
}
body .profile__confirm-input-pass {
    width: 48.6%;
}
.problem__popup-title.logout {
    margin-bottom: 40px;
}
body .profile__lastname-input.card-input {
    margin-bottom: 10px;
}
.popup__address-content {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.final-price {
    font-weight: 700;
}
.product-close {
    width: 20px;
    height: 20px;
    margin-top: 28px;
}

.profile__no-address {
    display: block;
}

.profile__no-address.profile__noaddress-pasive {
    display: none;
}

.profile__addresses.profile-address-disabled {
    display: none;
}
.profile__addresses {
    display: block;
    .pagination {
        margin-top: 48px;
    }
}
.profile__order-products {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    max-height: 500px;
    overflow-y: auto;
}
.profile__order-products.active {
    height: 100%;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
}
.popup__address.active__popup {
    display: block;
    transition: all 0.3s;
}
.popup {
    &__address {
        display: none;
        border: 1px solid #a9a9a9;
        position: fixed;
        background: #fff;
        top: 0;
        left: 0;
        width: 420px;
        transition: all 0.3s;
        z-index: 999999;
        height: 100vh;
        .cart__back {
            display: none;
        }
    }

    &__address-default,
    &__address-contact,
    &__address-newadd {
        border-bottom: 1px solid #a9a9a9;
        padding-bottom: 14px;
    }
    &__default-title,
    &__contact-title,
    &__newadd-title {
        margin-bottom: 16px;
        margin-top: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #131313;
    }
    &__container {
        margin-right: 30px;
        margin-left: 30px;
        position: relative;
    }
    &__default-input,
    &__contact-input,
    &__newadd-input {
        margin-bottom: 30px !important;
    }

    &__create {
        margin: 30px;
        cursor: pointer;
        margin-top: auto;
    }

    &__create-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-height: 52px;
        color: #fff;
        background: #d5ba6c;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding-top: 16.5px;
        padding-bottom: 16.5px;
        border: 1px solid #d5ba6c;
        transition: all 0.3s;
        &:hover {
            border: 1px solid #000;
            background: transparent;
            color: #000;
            transition: all 0.3s;
        }
    }
}
.problem__popup {
    background: #ffffff;
    padding: 20px 60px;
    width: 686px;
    position: relative;
    margin: 0 10px;
    max-width: 566px;
    input {
        margin-bottom: 10px;
    }
    .error-mesage {
        margin-bottom: 5px;
    }
    .contact__login::before {
        display: none;
    }
    &-title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
    &-text {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 40px;
    }
    .close-product {
        width: 11px;
        height: 11px;
    }
    .product__popup-close {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
    .contact__send {
        margin-top: 80px;
        margin-right: auto;
        margin-left: auto;
    }
}
.problem__popup {
    .contact__send {
        max-width: 240px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1024px) {
    .profile {
        &__show-password {
            max-width: 100%;
        }
        &__password-eye {
            right: 20px;
            top: -60px;
        }
        &__addresses {
            .pagination {
                margin-top: 48px;
            }
        }
        &__new-address--content {
            margin-top: 24px;
        }
        &__bottom-btns {
            flex-direction: column;
        }
        &__logout {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
            font-size: 13px;
            line-height: 16px;
        }
        .contact__message-input {
            padding-left: 10px !important;
        }
        &__data {
            margin-bottom: 35px;
        }
        &__add-address {
            display: block;
        }
        &__top-btns {
            display: block;
            margin-bottom: 30px;
        }
        &__personaldata-text,
        &__myaddress-text,
        &__myorder-text {
            font-size: 13px;
            line-height: 16px;
        }
        &__address {
            margin-bottom: 35px;
        }
        &__data-title {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 8px;
        }
        &__userdata-title,
        &__password-title,
        &__contact-title,
        &__card-title,
        &__address-title,
        &__myorder-title,
        &__mailadd-title,
        &__delivery-text {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            padding-bottom: 22px;
        }
        &__delivery-text {
            margin-bottom: 16px;
            padding-bottom: 0;
        }
        &__address-title {
            margin-bottom: 8px;
            padding-bottom: 0;
        }
        &__add-address {
            margin-top: 24px;
        }

        &__top-btn {
            display: flex;
            margin-right: 0;
            justify-content: center;
            align-items: center;
        }
        &__newpass-inputs,
        &__input-userdata,
        &__input-mail {
            display: block;
        }
        &__lastname-input,
        &__password-input {
            max-width: 100%;
        }
        &__update-text {
            max-width: 100%;
            text-align: center;
            font-size: 13px;
            line-height: 16px;
            width: 100%;
        }
        &__add-text {
            max-width: 100%;
            text-align: center;
        }
        &__current-top,
        &__new-top {
            display: block;
        }
        &__new-address--content,
        &__current-address--content {
            padding: 10px;
        }
        &__current-control,
        &__new-control {
            margin-top: 40px;
            display: block;
        }
        &__current-delete,
        &__current-edit,
        &__new-delete,
        &__new-edit {
            margin-left: 0;
        }
        &__current-change,
        &__new-change {
            justify-content: space-between;
            margin-top: 20px;
        }
        &__order-top {
            justify-content: start;
        }
        &__top-btn:not(:last-child) {
            border-bottom: none;
        }
        &__control-report {
            display: none;
        }
        &__control-completed {
            margin-left: 10px;
        }
        &__order-control {
            justify-content: space-between;
        }
        &__total-bottom,
        &__order-list {
            margin-top: 10px;
        }
        &__total-bottom {
            margin-right: 20px;
        }
        &__total-text,
        &__total-money {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 36px;
        }
        &__order-content {
            padding: 10px;
        }
        &__order-bottom {
            margin-left: 10px;
        }
        &__order-bg {
            margin-left: -10px;
        }
        &__report-mobile {
            cursor: pointer;
            display: inline-block;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.01em;
            color: #d5ba6c;
            transition: all 0.3s;
            &:hover {
                color: #000;
                transition: all 0.3s;
            }
        }
        &__product-img {
            max-width: 70px;
            max-height: 86px;
        }
        &__name-title {
            margin-top: 0;
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 19px;
        }
        &__product-name {
            margin-left: 20px;
        }
        &__product-cantity {
            font-size: 16px;
            line-height: 19px;
            display: none;
        }
        &__product-cantity-mobile {
            display: block;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.05em;
            margin-top: 5px;
        }
        &__order-discount {
            color: #a9a9a9;
        }
        .bag-popup__details {
            margin-top: 5px;
        }
        &__order-cantity {
            margin-bottom: 0px;
        }
    }
}

.popup__address-wrapper {
     [class^=' css'][class$='control'],
     [class^=' css'][class$='container'],
     [class^=' css'][class$='ValueContainer'],
     [class^=' css'][class$='Input'],
     [class^=' css'][class$='placeholder'] {
        height: 52px !important;
        max-height: 52px !important;
        border-radius: 0px !important;
        box-shadow: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 17px !important;
        line-height: 20px !important;
        color: #474747 !important;
        cursor: pointer;
    }
     [class^=' css'][class$='container'] {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            right: 20px;
            background-image: url(../../../assets/images/icons/drop-white.svg);
        }
    }
     [class^=' css'][class$='control'] {
        padding: 0 10px 10px 10px;
    }
     [class^=' css'][class$='ValueContainer'] {
        padding-top: 0;
        padding-left: 15px;
    }
     [class^=' css'][class$='control']  [class^=' css'][class$='ValueContainer']{
        padding-left: 5px;
    }

     [class^=' css'][class$='Input'] {
        margin: 0;
        padding: 0;
    }
     [class^=' css'][class$='control'] {
        margin: 0 !important;
        display: flex;
        align-items: center;
        &>div {
            height: 52px;
        }
    }
    [class^=' css'][class$='indicatorSeparator'] {
        display: none;
    }
     [class^=' css'][class$='placeholder'] {
        padding: 14px 0px;
        margin: 0 !important;
        display: flex;

    }
     [class^=' css'][class$='IndicatorsContainer'] {
        display: none !important;
    }
     [class^=' css'][class$='control'] {
        border: 1px solid #474747 !important;
        & input {
        }
    }
     [class^=' css'][class$='menu'] {
        padding: 0 !important;
        border-radius: 0px !important;
        border: 1px solid #a9a9a9;
        margin: 0 !important;
        max-height: 208px;
        overflow: hidden;
    }
     [class^=' css'][class$='MenuList'] {
        padding: 0 !important;
    }
     [class^=' css'][class$='option']{
        height: 52px !important;
        padding: 15px 20px !important;
        color: #a9a9a9 !important;
        font-weight: 400 !important;
        font-size: 17px !important;
        line-height: 20px !important;
        cursor: pointer;
        background: #f3f3f3 !important;
        transition: all 0.3s;
    }
     [class^=' css'][class$='option'] {
        height: 52px !important;
        padding: 15px 20px !important;
        color: #a9a9a9 !important;
        font-weight: 400 !important;
        font-size: 17px !important;
        line-height: 20px !important;
        cursor: pointer;
        background: #fff !important;
        transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            background: #f3f3f3 !important;
        }
    }
}
.popup__address-wrapper  [class^=' css'][class$='container']::after {
    display: none;
}
@media (max-width: 1024px) {
    .problem__popup .contact__send {
        margin-top: 65px;
    }
    .popup__address {
        .cart__back {
            cursor: pointer;
            display: inline-block;
            margin-top: 42px;
            margin-bottom: 22px;
        }
    }
    .profile__completed-text,
    .profile__current-title,
    .profile__new-title {
        font-size: 20px;
        line-height: 30px;
    }
    .profile__control-arrow {
        position: absolute;
        right: 10px;
    }
    .profile__order-content {
        position: relative;
        margin-bottom: 10px;
    }
    .profile__curr-add {
        margin-bottom: 16px;
    }
    .popup__address {
        width: 360px;
    }
    .profile__myorder-title,
    .profile__address-title {
        margin-top: 30px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .profile__myorder-title {
        padding-bottom: 8px;
        font-weight: 500;
    }
    .profile__personaldata-text {
        padding: 13.5px 24px;
    }
    .profile__top-btn {
        height: 44px;
    }
    .profile__deliveryadd-input {
        font-size: 20px;
        line-height: 30px;
        height: 60px;
        padding-left: 10px;
    }
    .profile__completed-text {
        padding-bottom: 16px;
    }
    .profile__order-number {
        font-size: 0;
        span {
            font-size: 20px;
        }
    }

    .profile__order-discount {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 10px;
    }
    .popup__default-input:last-child,
    .popup__contact-input:last-child,
    .popup__newadd-input:last-child {
        margin-bottom: 15px !important;
    }
    .popup__default-title,
    .popup__contact-title,
    .popup__newadd-title {
        margin-top: 10px;
    }
    .popup__container {
        margin: 0 10px;
    }
    .popup__address-default {
        border-bottom: none;
    }
    .popup__create {
        margin: 10px;
    }
}
@media (max-width: 968px) {
    .problem__popup {
        padding: 40px 10px;
        &-title {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 15px;
        }
        &-text {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 1.5;
        }
        .problem__popup .contact__send {
            margin-top: 65px;
        }

        .contact__send {
            width: 100%;
            max-width: 100%;
        }
    }
    .profile {
        .logout {
            &__btn-logout {
                width: 100%;
                width: 130px;
            }
            &__btn-cancel {
                width: 100%;
                width: 130px;
            }
        }
    }
    body .profile__confirm-input-pass {
        width: 100%;
    }
}
